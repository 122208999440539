/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";

import Footer from "../Footer";
import { getStorage } from "../../../helpers/utils";

function Main(props) {
  const { currentPage, onPageChange, onGuidedPageChange } = props;

  const [userName, setUsername] = useState('')
  const [userData, setUserdata] = useState({
    Persona: "NIE"
  })

  const handleClick = (value) => {
    onPageChange(value);
  };

  const changeGuidedPage = (value) => {
    onGuidedPageChange(value);
  }

  useEffect(() => {
    let userData = getStorage('currentLoggedInUser');
    
    if (userData) {
        setUserdata(userData)
        setUsername(userData.User_info.displayName)
    } else {
        // back to login condition
    }

    onGuidedPageChange(1);
}, [])

    return (
    <div className="pt-4 px-4 new-main text-default-blue bg-gray">
      <div>
        <div className="row m-0 text-default-blue">
            <div className="col-12 p-0" style={{ marginTop: "25px", marginBottom: "24px" }}>
                <h5>Hello, <span className="text-blue">{userName ? userName: ''}</span>!</h5>
            </div>
            <div className="col-12 p-0" style={{ marginBottom: "24px" }}>
                <p className="text-default-blue font-abadimt-prolight" style={{ fontWeight: "medium" }}>
                    I am eagerly awaiting your collaboration to create blended courses/ lessons. Select one of the quick actions below to kickstart our partnership!
                </p>
            </div>
            <div className="col-12 p-0" style={{ marginBottom: "16px" }} onClick={() => handleClick('guided')}>
                <div className="card mx-3" style={{ padding: "16px" }}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 p-0" style={{ marginBottom: "12px" }}>
                                <img src={require("../../assets/new-version/file-open.png")} width={20} alt="Create Your Course Design" />
                            </div>
                            <div className="col-12 p-0 text-default-blue font-abadimt-probold" style={{ marginBottom: "8px" }}>
                                <b>{userData.Persona == 'MOE' ? "Create Your Lesson Plan" : "Create Your Course Design"}</b>
                            </div>
                            <div className="col-12 p-0 font-abadimt-prolight">
                                <p className="text-default-blue">
                                    {userData.Persona == 'MOE' ? "Ready to craft your Lesson Plan? Use my guided prompt to build your perfect Lesson Plan. I’ll be your trusted partner, walking you through every step, ensuring you create an outstanding Lesson Plan effortlessly." : "Ready to craft your course? Use my guided prompt to build your perfect course design. I’ll be your trusted partner, walking you through every step, ensuring you create an outstanding course effortlessly."}
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 p-0">
                <div className="card mx-3" style={{ padding: "16px" }} onClick={() => handleClick('askquestion')}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 p-0" style={{ marginBottom: "12px" }}>
                            <img src={require("../../assets/new-version/unread-chat.png")} width={20} alt="Ask a question" />
                            </div>
                            <div className="col-12 p-0 text-default-blue font-abadimt-probold" style={{ marginBottom: "8px" }}>
                                <b>Ask a question</b>
                            </div>
                            <div className="col-12 p-0 font-abadimt-prolight">
                                <p className="text-default-blue">
                                    Have any questions or need advice? Whether it's about improving your {userData.Persona == 'MOE' ? "lesson plan" : "course outline"} or any other topic, I'm here to provide answers and suggestions to make things even better!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer routerHandler={handleClick} className="text-center"/>
    </div>
  );
}

export default Main;
