/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { Navbar } from "../Navbar";
import Button from "../utility/Button";
import { getStorage, setStorage, removeStorage } from "../../../helpers/utils";
import axios from "axios";

import { DEFAULT_AZURE_FUNCTION_URL, STORE_USER_INFO_CODE, CMS_USER_LIST_CODE } from "../../../environment";

export default function WritingBackground({ routerHandler, onBoardingPageChange }) {
  const [instruction, instructionSet] = useState("");

  const pageChange = (value) => {
    onBoardingPageChange(value);
  };

  const routerChange = (value) => {
    routerHandler(value);
  };

  const updateInstruction = () => {
    const tokenFinal = getStorage("tokenFinal");
    const currentUserData = getStorage("currentLoggedInUser");
    if (tokenFinal) {
      axios
        .get("https://graph.microsoft.com/v1.0/me", {
          headers: {
            Authorization: "Bearer " + tokenFinal,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            axios
              .post(
                `${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_Store_User_Info?code=${STORE_USER_INFO_CODE}`,
                {
                  user_data: res.data,
                  persona: currentUserData.Persona,
                  custom_instruction: instruction,
                  is_pass_onboarding: false,
                },
                {
                  headers: {
                    Authorization: "Bearer" + tokenFinal,
                  },
                }
              )
              .then((res2) => {
                setStorage("currentLoggedInUser", res2.data.user_information[0]);
                pageChange("start");
              });
          }
        })
        .catch((err) => {
          removeStorage("currentLoggedInUser");
          removeStorage("Office API client");
          removeStorage("tokenFinal");
          removeStorage("bearerToken");
          window.location.reload();
          console.error(err);
        });
    }
  };

  return (
    <Navbar routerHandler={routerChange} inChatPage={true}>
      <div className="greeting row mx-0 justify-center text-center px-4 overflow-x-hidden">
        <div className="col-12 p-0 my-auto">
          <h3 className="font-abadimt-probold font-lg mb-4 text-default-blue">C’mon.. Tell me more..</h3>
          <p className="font-abadimt-light font-md mb-4 text-default-blue">
            How about you share what you teach, who your students are, or any other relevant information so that we can
            collaborate more effectively.
          </p>
          <textarea
            className="border-gray mb-3"
            name="background"
            id="background"
            cols={10}
            rows={7}
            onChange={(e) => {
              instructionSet(e.target.value);
            }}
          ></textarea>
        </div>
        <div className="col-12 p-0 mb-3 text-center">
          <div className="mx-0 row align-items-center justify-content-center">
            <div className="col-4 p-0 text-center">
              <Button
                className="text-default-blue font-abadimt-prolight font-md px-4"
                text="Back"
                onClick={() => pageChange("persona")}
              />
            </div>
            <div className="col-4 p-0 text-center">
              <Button
                className="text-default-blue font-abadimt-prolight font-md px-4"
                text="Next"
                onClick={() => updateInstruction()}
              />
            </div>
          </div>
        </div>
        <div className="col-12 p-0">
          <u
            className="font-color-gray font-md font-abadimt-light cursor-pointer text-default-blue"
            onClick={() => pageChange("skipstart")}
          >
            Skip this setup for now
          </u>
        </div>
      </div>
    </Navbar>
  );
}
