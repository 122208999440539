/* eslint-disable prettier/prettier */
import React, {useState} from "react";
import Button from "../utility/Button";
import BackHome from "../utility/BackHome";

const FavouritePrompt = ({ routeHandler, pageHandler }) => {

    const [highlight, setHighlight] = useState(1)

    const routeChange = (value) => {
        routeHandler(value);
    }

    const pageChange = (value) => {
        pageHandler(value);
    }

    return (
        <div className="p-2">
            <BackHome backFunction={() => pageChange(1)} routeHandler={routeChange}/>
            <div className="row justify-content-start items-align-center mx-0 mt-3 p-0">
                <h3 className="font-abadimt-extralight col-12 p-0 px-2 font-xl">Favourite Prompts</h3>
                <p onClick={() => {}} className="col-12 p-0 px-2 font-action-url font-md text-green-500 font-abadimt-extralight">Why should you use ‘Favourite Prompts’?</p>
                <div className="col-12 p-0 mb-3">
                    <div className={`card mx-0 default-card clickable ${highlight === 1 ? 'active' : ''}`} onClick={() => setHighlight(1)}>
                        <div className="card-body">
                            <p className="font-abadimt-extralight">
                                Generate a course overview for 'Introduction to Computer Science':
                                <ul>
                                    <li>Course Title: Introduction to Computer Science</li>
                                    <li>Course Duration: 12 weeks</li>
                                    <li>Instructional Method: Utilize the ABCD teaching method (Audience, Behavior, Condition, Degree) to outline course objectives and outcomes.</li>
                                    <li>Incorporate the TE21 framework to ensure student engagement and active learning.</li>
                                    <li>Utilize online resources, interactive labs, and textbooks as key educational resource</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 mb-3">
                    <div className={`card mx-0 default-card clickable ${highlight === 2 ? 'active' : ''}`} onClick={() => setHighlight(2)}>
                        <div className="card-body">
                            <p className="font-abadimt-extralight">
                                Suggest learner-centred teaching and learning activities anchored in inquiry-based learning and collaborative learning with the use of edtech tools in relation to the intended learning outcomes and assessment tasks
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 mb-3">
                    <div className={`card mx-0 default-card clickable ${highlight === 3 ? 'active' : ''}`} onClick={() => setHighlight(3)}>
                        <div className="card-body">
                            <p className="font-abadimt-extralight">
                                Provide response where the structure of the course aligns with the '12-week' duration|
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 text-center mt-3">
                    <Button onClick={() => {}} text="Use Prompt" iconSize={15} icon={require("../../assets/fs-comment-box.png")}/>
                </div>
            </div>
        </div>
    )
}

export default FavouritePrompt;
