var store = require('store-js');
// import store from 'store-js';

export function setStorage(key, value) {
  return store.set(key, value);
}

export function getStorage(key) {
  return store.get(key);
}

export function removeStorage(key) {
  return store.remove(key);
}