/* eslint-disable react/jsx-key */
/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import { getStorage } from "../../../helpers/utils";
import axios from "axios";
import {
  ALEX_CONVERSATIONAL_CHAT_CODE,
  ALEX_MOE_LESSON_CODE,
  ALEX_NIE_COURSE_CODE,
  ALEX_REFINEMENT_INQUIRY_CODE,
  BROWSE_PROMPT_CODE,
  DEFAULT_AZURE_FUNCTION_URL,
  FAVORITE_PROMPT_CODE,
  PROMPT_CATEGORY_CODE,
} from "../../../environment";
import Typewriter from "typewriter-effect";

export default function StepTwo(props) {
  const {
    goToGuidedPage,
    handleClick,
    onChangeisNavigationComingFromCourseDesign,
    isNavigationComingFromCourseDesign,
    isTE21,
    isLearningResource,
    is5C,
    onSetIsTE21,
    onSetIsLearningResource,
    onSetIs5C,
    onChangeTypeGeneration,
    typeGeneration,
    questionToBeAsked,
    onChangeAnswerResult,
    chatResponse,
    setIsiKonten,
    textingFunc,
    isiGuided1,
    isiGuided1HTML,
    resetChat,
    uuid,
    durationInMins,
    studentLevel,
    topics,

    // isiKonten,
    // submitChat,
    // highlightedText
  } = props;

  const [isShowModal, setIsShowModal] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [resetModal, setResetModal] = useState(false);
  const [response, setResponse] = useState(null);
  const [rawResponse, setRawResponse] = useState(null);
  const [checkedT2, setCheckedT2] = useState(false);
  const [checked5C, setChecked5C] = useState(false);
  const [checkedLR, setCheckedLR] = useState(false);
  const [appliedOption1, setAppliedOption1] = useState(false);
  const [appliedOption2, setAppliedOption2] = useState(false);
  const [appliedOption3, setAppliedOption3] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [prompt, setPrompt] = useState(isiGuided1);
  const [promptFE, setPromptFE] = useState(isiGuided1);
  const [compilationQuestionAnswer, setCompilationQuestionAnswer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTextarea, setIsTextarea] = useState(false);
  // PROMPT CATEGORY
  const [categoryOption, categoryOptionSet] = useState([]);
  const [choosenCategory, choosenCategorySet] = useState("");
  const [promptLibData, PromptLibDataSet] = useState([]);
  const [highlight, setHighlight] = useState(0);
  const [addtoFav, setAddtoFav] = useState(false);
  const [isConv, setIsConv] = useState(false);
  const scrollContainerRef = useRef(null);
  const [isFavoriteActive, isFavoriteActiveSet] = useState(false);
  const [promptFavoriteData, promptFavoriteDataSet] = useState([]);
  const [status1to2, setStatus1to2] = useState(true);
  const [statusNieMoeQuestion, setStatusNieMoeQuestion] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [useHighlight, setUseHighlight] = useState(false);

  const [highlightedText, setHighlightedText] = useState("");
  const [wordContent, setWordContent] = useState("");
  const [displayHighlightedText, setDisplayHighlightedText] = useState("");
  const [isWaitingResponse, setIsWaitingResponse] = useState(false);

  const [isVisibleT2, setIsVisibleT2] = useState(false);
  const [isVisible5C, setIsVisible5C] = useState(false);
  const [isVisibleLR, setIsVisibleLR] = useState(false);

  const [isDisplayInformation, setIsDisplayInformation] = useState(false);

  // PROMPT CATEGORY END

  const onChangeSetIsTE21 = () => {
    onSetIsTE21(!isTE21);
  };

  const onChangeIsLearningResource = () => {
    onSetIsLearningResource(!isLearningResource);
  };

  const onChangeIs5C = () => {
    onSetIs5C(!is5C);
  };

  const handleItemClick = (eventKey) => {
    setActiveKey(eventKey);
  };

  const handleCloseButtonClick = () => {
    setActiveKey(null);
  };

  const handleClose = () => {
    setIsShowModal(false);
  };

  function SpanWithTooltip({ id, children, tooltip }) {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip className="my-custom-tooltip-2" id={id}>
            {tooltip}
          </Tooltip>
        }
        placement="top"
      >
        <span className={""}>{children}</span>
      </OverlayTrigger>
    );
  }

  function selectionChangedHandler() {
    setPrompt("");
    setPromptFE("");

    try {
      Word.run(async (context) => {
        // Get the current selection in the Word document
        const range = context.document.getSelection();

        // Load the text of the selection
        range.load("text");

        // Sync the document state and get the selection text
        await context.sync();

        // Update the state with the selected text
        setPrompt("I want to know more about " + range.text);
        setPromptFE("I want to know more about " + range.text);
        setHighlightedText(range.text);

        if (range.text.length > 50) {
          setDisplayHighlightedText(range.text.substring(0, 50).concat("..."));
        } else {
          setDisplayHighlightedText(range.text);
        }
      });
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  useEffect(() => {
    Office.onReady(() => {
      Word.run(async (context) => {
        const body = context.document.body.paragraphs;
        body.load("text");
        await context.sync();
        // const documentText = (body.text).replace(/[\u0000-\u001F\u007F-\u009F]/g, "");;
        let documentText = "";
        body?.items?.map((data) => {
          if (data.text !== "") {
            documentText += data.text.replace(/[\u0000-\u001F\u007F-\u009F]/g, "") + "\n";
          }
        });
        setWordContent(documentText);
      });
      Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, selectionChangedHandler);
    }).catch((err) => console.error(err));
  });

  //   PROMPT LIBRARY
  useEffect(() => {
    const tokenFinal = getStorage("tokenFinal");
    // GET CATEGORY
    axios
      .get(DEFAULT_AZURE_FUNCTION_URL + "/api/CMS_Prompt_Category?code=" + PROMPT_CATEGORY_CODE, {
        headers: {
          Authorization: "Bearer " + tokenFinal,
        },
      })
      .then((res) => {
        let tmpData = [];
        let response = res.data;

        tmpData.push({ value: "favourite", label: "Favourite Prompts" });

        response.map((val) => {
          tmpData.push({ value: val.Category_id, label: val.Category });
        });

        categoryOptionSet(tmpData);
      })
      .catch((err) => console.log(err));

    //   GET ALL PROMPT LIBRARY DATA
    axios
      .get(DEFAULT_AZURE_FUNCTION_URL + "/api/CMS_Browse_Prompt?code=" + BROWSE_PROMPT_CODE, {
        headers: {
          Authorization: "Bearer " + tokenFinal,
        },
      })
      .then((res) => {
        let data = res.data.prompt_list;

        if (data.length > 0) {
          PromptLibDataSet(data);
        } else {
          PromptLibDataSet([]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const tokenFinal = getStorage("tokenFinal");
    axios
      .get(
        `${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_Browse_Prompt?code=${BROWSE_PROMPT_CODE}&category_ids=${choosenCategory}`,
        {
          headers: {
            Authorization: "Bearer " + tokenFinal,
          },
        }
      )
      .then((res) => {
        let data = res.data.prompt_list;

        if (data.length > 0) {
          PromptLibDataSet(data);
        } else {
          PromptLibDataSet([]);
        }
      })
      .catch((err) => console.log(err));
  }, [choosenCategory]);

  useEffect(() => {
    if (isFavoriteActive) {
      const userData = getStorage("currentLoggedInUser");
      const userEmail = userData.User_info.mail;
      const tokenFinal = getStorage("tokenFinal");

      axios
        .get(
          `${DEFAULT_AZURE_FUNCTION_URL}/api/Favourite_prompt?code=${FAVORITE_PROMPT_CODE}&user_email=${userEmail}`,
          {
            headers: {
              Authorization: "Bearer " + tokenFinal,
            },
          }
        )
        .then((res) => {
          let tmpFavData = [];

          res.data.map((val) => {
            tmpFavData.push({ id: val.favourite_prompt_id, prompt: val.prompt });
          });

          promptFavoriteDataSet(tmpFavData);
        })
        .catch((err) => console.log(err));
    } else {
      promptFavoriteDataSet([]);
    }
  }, [isFavoriteActive, isShowModal]);

  //   FUNCTIONS
  const onChoosenCategoryChanged = (value) => {
    let stringParse: string = "";

    if (value.length > 0) {
      let countFavorite = 0;

      value.map((val, idx) => {
        if (Number.isInteger(val.value)) {
          stringParse += stringParse.length > 0 ? "," + val.value.toString() : val.value.toString();
        } else {
          countFavorite += 1;
        }

        if (idx == value.length - 1) {
          if (countFavorite > 0) isFavoriteActiveSet(true);
          else isFavoriteActiveSet(false);
        }
      });

      choosenCategorySet(stringParse);
    } else {
      choosenCategorySet("");
      isFavoriteActiveSet(false);
    }
  };

  const onUsePrompt = () => {
    setPromptFE("");
    setPrompt("");
    setIsShowModal(false);

    if (isFavoriteActive) {
      setPromptFE(promptFavoriteData[highlight - 100].prompt);
      setPrompt(promptFavoriteData[highlight - 100].prompt);
    } else {
      setPromptFE(promptLibData[highlight].Prompt);
      setPrompt(promptLibData[highlight].Prompt);
    }

    // Reset
    choosenCategorySet("");
    setHighlight(-1);
  };

  //   PROMPT LIBRARY END

  useEffect(() => {
    if (highlightedText == "") {
      setUseHighlight(false);
    } else {
      setUseHighlight(true);
    }

    if (appliedOption1 && appliedOption2 && appliedOption3) {
      setButtonStatus(true);
    }

    if (appliedOption3 && isiGuided1) {
      setIsLoading(true);

      setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, ""]);

      const ENDPOINT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_NIE_Course";
      const ENDPOINT_MOE_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_MOE_Lesson";

      const apiCode = ALEX_NIE_COURSE_CODE;
      const apiCodeMoe = ALEX_MOE_LESSON_CODE;
      const isiKonten = isiGuided1;
      const currentData = getStorage("currentLoggedInUser");
      const persona = currentData?.Persona;
      const tokenFinal = getStorage("tokenFinal");

      setIsWaitingResponse(true);
      if (persona === "NIE") {
        const requestPayload = {
          prompt: isiKonten,
          session_id: uuid,
          user_email: currentData?.User_info?.mail,
          name: currentData?.User_info?.displayName,
          activity: "Guided Course",
        };
        axios
          .post(ENDPOINT_URL + "?code=" + apiCode, requestPayload, {
            headers: {
              "x-functions-key": apiCode,
              Authorization: "Bearer" + tokenFinal,
            },
          })
          .then((resp) => {
            setRawResponse(resp);
            setPrompt("");
            setPromptFE("");
            setStatus1to2(true);
            setStatusNieMoeQuestion(true);
            setIsWaitingResponse(false);
          })
          .catch((error) => {
            if (error?.response?.data?.controversial_content) {
              setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                ...compilationQuestionAnswer,
                [
                  {
                    error: error?.response?.data?.controversial_content,
                  },
                ],
              ]);
            } else if (error?.response?.data?.token_error) {
              setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                ...compilationQuestionAnswer,
                [
                  {
                    // error: error?.response?.data?.token_error
                    error:
                      "Uh-oh! There's too much content in the Word document for me to handle. Try reducing the content to maximum 40 pages and/or 5000 words and give it another go.",
                  },
                ],
              ]);
            } else {
              setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                ...compilationQuestionAnswer,
                [
                  {
                    error: "Oops! Seems like ALEX is a little busy… Please submit your question again.",
                  },
                ],
              ]);
            }

            setButtonStatus(true);
            setIsLoading(false);
            setStatus1to2(false);
            setIsWaitingResponse(false);
            console.error(error);
          });
      } else {
        const requestPayload = {
          durationInMins: durationInMins,
          studentLevel: studentLevel,
          topics: topics,
          session_id: uuid,
          user_email: currentData?.User_info?.mail,
          name: currentData?.User_info?.displayName,
          activity: "Guided Course",
        };

        setIsWaitingResponse(true);
        axios
          .post(ENDPOINT_MOE_URL + "?code=" + apiCodeMoe, requestPayload, {
            headers: {
              "x-functions-key": apiCodeMoe,
              Authorization: "Bearer" + tokenFinal,
            },
          })
          .then((resp) => {
            setRawResponse(resp);
            setPrompt("");
            setPromptFE("");
            setStatus1to2(true);
            setStatusNieMoeQuestion(true);
            setIsWaitingResponse(false);
          })
          .catch((error) => {
            if (error?.response?.data?.controversial_content) {
              setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                ...compilationQuestionAnswer,
                [
                  {
                    error: error?.response?.data?.controversial_content,
                  },
                ],
              ]);
            } else if (error?.response?.data?.token_error) {
              setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                ...compilationQuestionAnswer,
                [
                  {
                    // error: error?.response?.data?.token_error
                    error:
                      "Uh-oh! There's too much content in the Word document for me to handle. Try reducing the content to maximum 40 pages and/or 5000 words and give it another go.",
                  },
                ],
              ]);
            } else {
              setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                ...compilationQuestionAnswer,
                [
                  {
                    error: "Oops! Seems like ALEX is a little busy… Please submit your question again.",
                  },
                ],
              ]);
            }

            setButtonStatus(true);
            setIsLoading(false);
            setStatus1to2(false);
            setIsWaitingResponse(false);
            console.error(error);
          });
      }
    }
  }, [appliedOption1, appliedOption2, appliedOption3]);

  const handleSubmitChat = () => {
    if (status1to2) {
      setIsConv(true);

      if (!appliedOption1 && !appliedOption2 && !appliedOption3) {
        return;
      } else {
        // setPromptFE("");

        if (prompt) {
          setIsLoading(true);

          setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, prompt]);

          const ENDPOINT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_Conversational_Chat";
          const ENDPOINT_HIGHLIGHT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_Refinement_Inquiry";

          const apiCode = ALEX_CONVERSATIONAL_CHAT_CODE;
          const HIGHLIGHT_CODE = ALEX_REFINEMENT_INQUIRY_CODE;
          const isiKonten = prompt;
          const currentData = getStorage("currentLoggedInUser");
          const persona = currentData?.Persona;
          const tokenFinal = getStorage("tokenFinal");

          const requestPayload = {
            word_content: wordContent,
            prompt: isiKonten,
            custom_instruction: null,
            session_id: uuid,
            user_email: currentData?.User_info?.mail,
            name: currentData?.User_info?.displayName,
            activity: "Ask a question",
          };

          setIsWaitingResponse(true);
          if (highlightedText == "") {
            axios
              .post(ENDPOINT_URL + "?code=" + apiCode, requestPayload, {
                headers: {
                  "x-functions-key": apiCode,
                  Authorization: "Bearer" + tokenFinal,
                },
              })
              .then((resp) => {
                setRawResponse(resp);
                setPrompt("");
                setPromptFE("");
                setIsWaitingResponse(false);
              })
              .catch((error) => {
                if (error?.response?.data?.controversial_content) {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        error: error?.response?.data?.controversial_content,
                      },
                    ],
                  ]);
                } else if (error?.response?.data?.token_error) {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        // error: error?.response?.data?.token_error
                        error:
                          "Uh-oh! There's too much content in the Word document for me to handle. Try reducing the content to maximum 40 pages and/or 5000 words and give it another go.",
                      },
                    ],
                  ]);
                } else {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        error: "Oops! Seems like ALEX is a little busy… Please submit your question again.",
                      },
                    ],
                  ]);
                }

                setButtonStatus(true);
                setIsLoading(false);
                setIsWaitingResponse(false);
                console.error(error);
              });
          } else {
            axios
              .post(
                ENDPOINT_HIGHLIGHT_URL + "?code=" + HIGHLIGHT_CODE,
                {
                  word_content: wordContent,
                  highlighted_content: highlightedText,
                  prompt: isiKonten,
                  user_email: currentData?.User_info?.mail,
                  session_id: uuid,
                  activity: "Ask a question",
                },
                {
                  headers: {
                    "x-functions-key": HIGHLIGHT_CODE,
                    Authorization: "Bearer" + tokenFinal,
                  },
                }
              )
              .then((resp) => {
                setRawResponse(resp);
                setPrompt("");
                // setHighlightedText("")
                // setDisplayHighlightedText("")
                setPromptFE("");
                setIsWaitingResponse(false);
              })
              .catch((error) => {
                if (error?.response?.data?.controversial_content) {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        error: error?.response?.data?.controversial_content,
                      },
                    ],
                  ]);
                } else if (error?.response?.data?.token_error) {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        // error: error?.response?.data?.token_error
                        error:
                          "Uh-oh! There's too much content in the Word document for me to handle. Try reducing the content to maximum 40 pages and/or 5000 words and give it another go.",
                      },
                    ],
                  ]);
                } else {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        error: "Oops! Seems like ALEX is a little busy… Please submit your question again.",
                      },
                    ],
                  ]);
                }

                setButtonStatus(true);
                setIsLoading(false);
                setIsWaitingResponse(false);
                console.error(error);
              });
          }
        }
      }
    } else {
      setIsLoading(true);

      // setPromptFE("");
      let isiKonten = "";

      if (promptFE) {
        setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, promptFE]);
        isiKonten = promptFE;
      } else {
        setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, prompt]);
        isiKonten = prompt;
      }

      const ENDPOINT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_NIE_Course";
      const ENDPOINT_MOE_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_MOE_Lesson";

      const apiCode = ALEX_NIE_COURSE_CODE;
      const apiCodeMoe = ALEX_MOE_LESSON_CODE;

      const currentData = getStorage("currentLoggedInUser");
      const persona = currentData?.Persona;
      const tokenFinal = getStorage("tokenFinal");

      const requestPayload = {
        prompt: isiKonten,
        session_id: uuid,
        user_email: currentData?.User_info?.mail,
        name: currentData?.User_info?.displayName,
        activity: "Guided Course",
      };

      setIsWaitingResponse(true);
      if (persona === "NIE") {
        axios
          .post(ENDPOINT_URL + "?code=" + apiCode, requestPayload, {
            headers: {
              "x-functions-key": apiCode,
              Authorization: "Bearer" + tokenFinal,
            },
          })
          .then((resp) => {
            setRawResponse(resp);
            setPrompt("");
            setPromptFE("");
            setStatus1to2(true);
            setStatusNieMoeQuestion(true);
            setIsWaitingResponse(false);
          })
          .catch((error) => {
            setCompilationQuestionAnswer((compilationQuestionAnswer) => [
              ...compilationQuestionAnswer,
              [
                {
                  error: "Oops! Seems like ALEX is a little busy… Please submit your question again.",
                },
              ],
            ]);

            setButtonStatus(true);
            setIsLoading(false);
            setStatus1to2(false);
            setIsWaitingResponse(false);
            console.error(error);
          });
      } else {
        axios
          .post(ENDPOINT_MOE_URL + "?code=" + apiCodeMoe, requestPayload, {
            headers: {
              "x-functions-key": apiCodeMoe,
              Authorization: "Bearer" + tokenFinal,
            },
          })
          .then((resp) => {
            setRawResponse(resp);
            setPrompt("");
            setPromptFE("");
            setStatus1to2(true);
            setStatusNieMoeQuestion(true);
            setIsWaitingResponse(false);
          })
          .catch((error) => {
            setCompilationQuestionAnswer((compilationQuestionAnswer) => [
              ...compilationQuestionAnswer,
              [
                {
                  error: "Oops! Seems like ALEX is a little busy… Please submit your question again.",
                },
              ],
            ]);

            setButtonStatus(true);
            setIsLoading(false);
            setStatus1to2(false);
            setIsWaitingResponse(false);
            console.error(error);
          });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!buttonStatus || !prompt || !promptFE) {
      return;
    }

    if (status1to2) {
      setIsConv(true);
      setIsDisplayInformation(false);

      if (!appliedOption1 && !appliedOption2 && !appliedOption3) {
        return;
      } else {
        // setPromptFE("");

        if (prompt) {
          setIsLoading(true);

          setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, prompt]);

          const ENDPOINT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_Conversational_Chat";
          const ENDPOINT_HIGHLIGHT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_Refinement_Inquiry";

          const apiCode = ALEX_CONVERSATIONAL_CHAT_CODE;
          const HIGHLIGHT_CODE = ALEX_REFINEMENT_INQUIRY_CODE;
          const isiKonten = prompt;
          const currentData = getStorage("currentLoggedInUser");
          const persona = currentData?.Persona;
          const tokenFinal = getStorage("tokenFinal");

          const requestPayload = {
            word_content: wordContent,
            prompt: isiKonten,
            custom_instruction: null,
            session_id: uuid,
            user_email: currentData?.User_info?.mail,
            name: currentData?.User_info?.displayName,
            activity: "Ask a question",
          };

          setIsWaitingResponse(true);
          if (highlightedText == "") {
            axios
              .post(ENDPOINT_URL + "?code=" + apiCode, requestPayload, {
                headers: {
                  "x-functions-key": apiCode,
                  Authorization: "Bearer" + tokenFinal,
                },
              })
              .then((resp) => {
                setRawResponse(resp);
                setPrompt("");
                setPromptFE("");
                setIsWaitingResponse(false);
                setIsDisplayInformation(true);
              })
              .catch((error) => {
                if (error?.response?.data?.controversial_content) {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        error: error?.response?.data?.controversial_content,
                      },
                    ],
                  ]);
                } else if (error?.response?.data?.token_error) {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        // error: error?.response?.data?.token_error
                        error:
                          "Uh-oh! There's too much content in the Word document for me to handle. Try reducing the content to maximum 40 pages and/or 5000 words and give it another go.",
                      },
                    ],
                  ]);
                } else {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        error: "Oops! Seems like ALEX is a little busy… Please submit your question again.",
                      },
                    ],
                  ]);
                }

                setButtonStatus(true);
                setIsLoading(false);
                setIsWaitingResponse(false);
                setIsDisplayInformation(true);
                console.error(error);
              });
          } else {
            axios
              .post(
                ENDPOINT_HIGHLIGHT_URL + "?code=" + HIGHLIGHT_CODE,
                {
                  word_content: wordContent,
                  highlighted_content: highlightedText,
                  prompt: isiKonten,
                  user_email: currentData?.User_info?.mail,
                  session_id: uuid,
                  activity: "Ask a question",
                },
                {
                  headers: {
                    "x-functions-key": HIGHLIGHT_CODE,
                    Authorization: "Bearer" + tokenFinal,
                  },
                }
              )
              .then((resp) => {
                setRawResponse(resp);
                setPrompt("");
                // setHighlightedText("")
                // setDisplayHighlightedText("")
                setPromptFE("");
                setIsWaitingResponse(false);
                setIsDisplayInformation(true);
              })
              .catch((error) => {
                if (error?.response?.data?.controversial_content) {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        error: error?.response?.data?.controversial_content,
                      },
                    ],
                  ]);
                } else if (error?.response?.data?.token_error) {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        // error: error?.response?.data?.token_error
                        error:
                          "Uh-oh! There's too much content in the Word document for me to handle. Try reducing the content to maximum 40 pages and/or 5000 words and give it another go.",
                      },
                    ],
                  ]);
                } else {
                  setCompilationQuestionAnswer((compilationQuestionAnswer) => [
                    ...compilationQuestionAnswer,
                    [
                      {
                        error: "Oops! Seems like ALEX is a little busy… Please submit your question again.",
                      },
                    ],
                  ]);
                }

                setButtonStatus(true);
                setIsLoading(false);
                setIsWaitingResponse(false);
                setIsDisplayInformation(true);
                console.error(error);
              });
          }
        }
      }
    } else {
      setIsLoading(true);

      // setPromptFE("");
      let isiKonten = "";

      if (promptFE) {
        setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, promptFE]);
        isiKonten = promptFE;
      } else {
        setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, prompt]);
        isiKonten = prompt;
      }

      const ENDPOINT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_NIE_Course";
      const ENDPOINT_MOE_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_MOE_Lesson";

      const apiCode = ALEX_NIE_COURSE_CODE;
      const apiCodeMoe = ALEX_MOE_LESSON_CODE;

      const currentData = getStorage("currentLoggedInUser");
      const persona = currentData?.Persona;
      const tokenFinal = getStorage("tokenFinal");

      const requestPayload = {
        prompt: isiKonten,
        session_id: uuid,
        user_email: currentData?.User_info?.mail,
        name: currentData?.User_info?.displayName,
        activity: "Guided Course",
      };
      setIsWaitingResponse(true);
      if (persona === "NIE") {
        axios
          .post(ENDPOINT_URL + "?code=" + apiCode, requestPayload, {
            headers: {
              "x-functions-key": apiCode,
              Authorization: "Bearer" + tokenFinal,
            },
          })
          .then((resp) => {
            setRawResponse(resp);
            setPrompt("");
            setPromptFE("");
            setStatus1to2(true);
            setStatusNieMoeQuestion(true);
            setIsWaitingResponse(false);
            setIsDisplayInformation(true);
          })
          .catch((error) => {
            setCompilationQuestionAnswer((compilationQuestionAnswer) => [
              ...compilationQuestionAnswer,
              [
                {
                  error: "Oops! Seems like ALEX is a little busy… Please submit your question again.",
                },
              ],
            ]);

            setButtonStatus(true);
            setIsLoading(false);
            setStatus1to2(false);
            setIsWaitingResponse(false);
            setIsDisplayInformation(true);
            console.error(error);
          });
      } else {
        axios
          .post(ENDPOINT_MOE_URL + "?code=" + apiCodeMoe, requestPayload, {
            headers: {
              "x-functions-key": apiCodeMoe,
              Authorization: "Bearer" + tokenFinal,
            },
          })
          .then((resp) => {
            setRawResponse(resp);
            setPrompt("");
            setPromptFE("");
            setStatus1to2(true);
            setStatusNieMoeQuestion(true);
            setIsWaitingResponse(false);
          })
          .catch((error) => {
            setCompilationQuestionAnswer((compilationQuestionAnswer) => [
              ...compilationQuestionAnswer,
              [
                {
                  error: "Oops! Seems like ALEX is a little busy… Please submit your question again.",
                },
              ],
            ]);

            setButtonStatus(true);
            setIsLoading(false);
            setStatus1to2(false);
            setIsWaitingResponse(false);
            setIsDisplayInformation(true);
            console.error(error);
          });
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleChangeKonten = (e) => {
    let text = e.target.value;
    setPrompt(text);
    setPromptFE(text);
    // setIsiKonten(text)
  };

  useEffect(() => {
    if (rawResponse) {
      setIsLoading(false);

      let checkIfContainJSON = false;

      let response = null;

      if (!rawResponse?.data?.response?.hasOwnProperty("response")) {
        checkIfContainJSON = rawResponse?.data?.response?.includes("{");
      }

      if (checkIfContainJSON) {
        response = JSON.parse(rawResponse?.data?.response);

        const currentData = getStorage("currentLoggedInUser");
        const persona = currentData?.Persona;

        if (persona == "NIE") {
          Word.run(async (context) => {
            let ILOContent = "";
            if (Array.isArray(response["ILOs for entire course"])) {
              await response["ILOs for entire course"].map(async (data, index) => {
                ILOContent += index + 1 + ". " + data + "\n";
              });
            } else {
              ILOContent += "1. " + response["ILOs for entire course"][0].toString() + "\n";
              ILOContent += "2. " + response["ILOs for entire course"][1].toString() + "\n";
              ILOContent += "3. " + response["ILOs for entire course"][2].toString() + "\n";
              ILOContent += "4. " + response["ILOs for entire course"][3].toString() + "\n";
              ILOContent += "5. " + response["ILOs for entire course"][4].toString() + "\n";
            }

            let contentValues = "";
            let contentSkills = "";
            let contentKnowledge = "";
            try {
              response["TE21"]?.Values.map((data) => {
                const numbering_points = data["Numbering Points"].split(",");
                contentValues += data["Value name"] + ": \n";

                numbering_points.map((data2, index2) => {
                  if (index2 == 0) {
                    contentValues += "   " + data2 + "\n";
                  } else {
                    contentValues += "  " + data2 + "\n";
                  }
                });
              });

              response["TE21"]?.Skills.map((data) => {
                contentSkills += "- " + data + "\n";
              });

              response["TE21"]?.Knowledge.map((data) => {
                contentKnowledge += "- " + data + "\n";
              });
            } catch (error) {
              console.log(error);
            }

            let content5Cs = "";
            response["5Cs"].map(async (data) => {
              content5Cs += "- " + data + "\n";
            });

            let synopsisProcessed = "";
            response["Course synopsis"].split(".").map((content) => {
              if (content.length > 0) {
                synopsisProcessed += content + ".\n\n";
              }
            });

            let tableDataFirst = [
              ["Course synopsis", synopsisProcessed],
              ["Intended Learning Outcomes (ILO)", ILOContent],
              [
                "TE21",
                "Values: \n" +
                  contentValues +
                  "\n \n Skills: \n" +
                  contentSkills +
                  "\n \n Knowledge: \n" +
                  contentKnowledge,
              ],
              ["5Cs", content5Cs],
            ];

            if (checkedT2) {
              if (checked5C) {
                tableDataFirst = [
                  ["Course synopsis", synopsisProcessed],
                  ["Intended Learning Outcomes (ILO)", ILOContent],
                  [
                    "TE21",
                    "Values: \n" +
                      contentValues +
                      "\n \n Skills: \n" +
                      contentSkills +
                      "\n \n Knowledge: \n" +
                      contentKnowledge,
                  ],
                  ["5Cs", content5Cs],
                ];
              } else {
                tableDataFirst = [
                  ["Course synopsis", synopsisProcessed],
                  ["Intended Learning Outcomes (ILO)", ILOContent],
                  [
                    "TE21",
                    "Values: \n" +
                      contentValues +
                      "\n \n Skills: \n" +
                      contentSkills +
                      "\n \n Knowledge: \n" +
                      contentKnowledge,
                  ],
                ];
              }
            } else {
              if (checked5C) {
                tableDataFirst = [
                  ["Course synopsis", synopsisProcessed],
                  ["Intended Learning Outcomes (ILO)", ILOContent],
                  ["5Cs", content5Cs],
                ];
              } else {
                tableDataFirst = [
                  ["Course synopsis", synopsisProcessed],
                  ["Intended Learning Outcomes (ILO)", ILOContent],
                ];
              }
            }

            const tableFirst = context.document.body.insertTable(
              tableDataFirst.length,
              tableDataFirst[0].length,
              Word.InsertLocation.end,
              tableDataFirst
            );
            await context.sync();

            let tableData = [
              [
                "Week",
                "Course topics",
                "Intended Learning Outcomes (ILO)",
                "ATs",
                "TLAs",
                "Ed tech tools",
                "Learning resources",
                "Mode of learning",
              ],
            ];

            if (!checkedLR) {
              tableData = [
                [
                  "Week",
                  "Course topics",
                  "Intended Learning Outcomes (ILO)",
                  "ATs",
                  "TLAs",
                  "Ed tech tools",
                  "Mode of learning",
                ],
              ];
            }

            let loopWeeks = response.Week;
            if (response.hasOwnProperty("Weeks")) {
              loopWeeks = response.Weeks;
            }

            loopWeeks.map((data) => {
              if (checkedLR) {
                tableData.push([
                  data["Week"].toString(),
                  data["Course topics"],
                  Array.isArray(data["ILOs achieved"])
                    ? data["ILOs achieved"].length > 1
                      ? data["ILOs achieved"].join(", ")
                      : data["ILOs achieved"][0]
                    : data["ILOs achieved"],
                  data["ATs"],
                  Array.isArray(data["TLAs"])
                    ? data["TLAs"].length > 1
                      ? data["TLAs"].join(", ")
                      : data["TLAs"][0]
                    : data["TLAs"],
                  Array.isArray(data["Ed tech tools"])
                    ? data["Ed tech tools"].length > 1
                      ? data["Ed tech tools"].join(", ")
                      : data["Ed tech tools"][0]
                    : data["Ed tech tools"],
                  Array.isArray(data["Other learning resources"])
                    ? data["Other learning resources"].length > 1
                      ? data["Other learning resources"].join(", ")
                      : data["Other learning resources"][0]
                    : data["Other learning resources"],
                  Array.isArray(data["Mode of learning"])
                    ? data["Mode of learning"].join(", ")
                    : data["Mode of learning"],
                ]);
              } else {
                tableData.push([
                  data["Week"].toString(),
                  data["Course topics"],
                  Array.isArray(data["ILOs achieved"])
                    ? data["ILOs achieved"].length > 1
                      ? data["ILOs achieved"].join(", ")
                      : data["ILOs achieved"][0]
                    : data["ILOs achieved"],
                  data["ATs"],
                  Array.isArray(data["TLAs"])
                    ? data["TLAs"].length > 1
                      ? data["TLAs"].join(", ")
                      : data["TLAs"][0]
                    : data["TLAs"],
                  Array.isArray(data["Ed tech tools"])
                    ? data["Ed tech tools"].length > 1
                      ? data["Ed tech tools"].join(", ")
                      : data["Ed tech tools"][0]
                    : data["Ed tech tools"],
                  Array.isArray(data["Mode of learning"])
                    ? data["Mode of learning"].join(", ")
                    : data["Mode of learning"],
                ]);
              }
            });

            // Create a table in the Word document with the data
            const table = context.document.body.insertTable(
              tableData.length,
              tableData[0].length,
              Word.InsertLocation.end,
              tableData
            );
            await context.sync();

            context.document.body.insertParagraph(
              "This course was co-designed with ALEX, a Generative AI application",
              Word.InsertLocation.end
            );
            context.sync();
          });
        } else {
          Word.run(async (context) => {
            let CCECompetencies = "";
            let prior_knowledge = "";
            let lesson_objective = "";

            response["21st CC and CCE Competencies"].map((data, index) => {
              CCECompetencies += index + 1 + ". " + data + "\n";
            });

            response["Prior Knowledge"].map((data, index) => {
              prior_knowledge += index + 1 + ". " + data + "\n";
            });

            response["Lesson Objectives (Content & Process - Metacognition)"].map((data, index) => {
              lesson_objective += index + 1 + ". " + data + "\n";
            });

            let tableDataFirst = [
              ["Subject", response["Subject"]],
              ["Topic", response["Topic"]],
              ["Unit", response["Unit"]],
              ["21st CC and CCE Competencies", CCECompetencies],
              ["Prior Knowledge", prior_knowledge],
              ["Lesson Objectives (Content & Process - Metacognition)", lesson_objective],
            ];

            const tableFirst = context.document.body.insertTable(
              tableDataFirst.length,
              tableDataFirst[0].length,
              Word.InsertLocation.end,
              tableDataFirst
            );
            await context.sync();

            let lessonDevelopments = response["Lesson Development Information"];

            let tableData = [
              ["Time", "Lesson Development", "Rationale", "Formative Assessments", "EdTech", "Learning Resources"],
            ];

            lessonDevelopments.map((data) => {
              tableData.push([
                data["Time"].toString(),
                data["Lesson Development"].toString(),
                data["Rationale"].toString(),
                data["Formative Assessments"].toString(),
                data["EdTech"].toString(),
                data["Learning Resources"].toString(),
              ]);
            });

            // Create a table in the Word document with the data
            const table = context.document.body.insertTable(
              tableData.length,
              tableData[0].length,
              Word.InsertLocation.end,
              tableData
            );
            await context.sync();

            context.document.body.insertParagraph(
              "This course was co-designed with ALEX, a Generative AI application",
              Word.InsertLocation.end
            );
            context.sync();
          });
        }
      } else {
        // response khsuus TEXT
        response = rawResponse?.data?.response?.Response;

        Word.run(async (context) => {
          let contentResponse = response.split("\n");

          contentResponse.map(async (data) => {
            context.document.body.insertParagraph(data, Word.InsertLocation.end);
            await context.sync();
          });
        });
      }

      let Responses = [];

      if (isConv) {
        Responses = [
          {
            chat: rawResponse?.data?.Responses?.Responses,
            references: rawResponse?.data?.Responses?.References,
          },
        ];
      } else {
        Responses = [
          {
            response: response,
            Conclusion: rawResponse?.data?.Conclusion,
            Reference_explanation: rawResponse?.data?.Reference_explanation,
            Reflective_repsonce: rawResponse?.data?.Reflective_repsonce,
          },
        ];
      }

      setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, Responses]);
      // setResponse(response)
    }
  }, [rawResponse]);

  useEffect(() => {
    if (isLoading) {
      setButtonStatus(false);
    } else {
      if (appliedOption1 && appliedOption2 && appliedOption3) {
        setButtonStatus(true);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    const currentData = getStorage("currentLoggedInUser");
    const persona = currentData?.Persona;

    if (persona === "MOE") {
      setAppliedOption1(true);
      setAppliedOption2(true);
      setAppliedOption3(true);
    }
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [compilationQuestionAnswer, appliedOption3, appliedOption2, appliedOption1]);

  const addPromptToFavorite = (promptText) => {
    const userData = getStorage("currentLoggedInUser");

    setFavorites((prevFavorites) => {
      const index = prevFavorites.findIndex((fav) => fav.key === promptText);
      const tokenFinal = getStorage("tokenFinal");

      if (index !== -1) {
        // If the item is already in favorites, remove it
        const updatedFavorites = [...prevFavorites];
        const deletedItem = updatedFavorites.splice(index, 1)[0];

        const dataToDelete = {
          favourite_prompt_id: deletedItem.id,
        };

        axios
          .delete(DEFAULT_AZURE_FUNCTION_URL + "/api/Favourite_prompt?code=" + FAVORITE_PROMPT_CODE, {
            headers: {
              Authorization: "Bearer" + tokenFinal,
            },
            data: dataToDelete,
          })
          .then(() => {
            return "";
          })
          .catch((error) => {
            console.error(error);
          });

        return updatedFavorites;
      } else {
        // If the item is not in favorites, add it
        let data = {
          user_email: userData.User_info.mail,
          prompt: promptText,
        };

        axios
          .post(DEFAULT_AZURE_FUNCTION_URL + "/api/Favourite_prompt?code=" + FAVORITE_PROMPT_CODE, data, {
            headers: {
              Authorization: "Bearer" + tokenFinal,
            },
          })
          .then((res) => {
            if (res.status && res.status !== 200) {
              handleClick("auth-login");
            }

            const newItem = {
              key: promptText,
              isFavorite: true,
              id: res.data.favourite_prompt_id,
            };

            setFavorites([...prevFavorites, newItem]);
          })
          .catch((err) => {
            console.log(err);
            // Handle the error as needed
          });

        // Return the current array since the state update is asynchronous
        return prevFavorites;
      }
    });
  };

  useEffect(() => {}, [favorites]);

  const ShowedPromptCount = () => {
    if (isFavoriteActive && choosenCategory.length > 0) {
      return <b>Showing {promptFavoriteData.length + promptLibData.length} results</b>;
    } else if (isFavoriteActive && choosenCategory.length < 1) {
      return <b>Showing {promptFavoriteData.length} results</b>;
    } else if (!isFavoriteActive && choosenCategory.length > 0) {
      return <b>Showing {promptLibData.length} results</b>;
    } else {
      return <b>Showing {promptLibData.length} results</b>;
    }
  };

  const PromptLibList = () => {
    if (isFavoriteActive && choosenCategory.length > 0) {
      return (
        <>
          {promptFavoriteData.map((val, idx) => {
            return (
              <div
                key={idx + 100}
                className={`card mx-0 default-card ${highlight === idx + 100 ? "active" : ""}`}
                onClick={() => setHighlight(idx + 100)}
              >
                <div className="card-body">
                  <p className="font-abadimt-pronormal">
                    <div className="row mx-0 justify-content-start align-items-start">
                      <div className="col-auto p-0 me-1">
                        <span
                          className="font-abadimt-pronormal inline-block"
                          style={{
                            color: "#A1A1A1",
                            fontWeight: "normal",
                          }}
                        >
                          Related category:{" "}
                        </span>
                      </div>
                      <div className="col p-0 pe-1">
                        <div className="row mx-0 justify-content-start align-items-start font-abadimt-pronormal">
                          <div className="col-auto p-0 pe-1">
                            <span className="font-highlight" style={{ color: "#3E6CC7", fontWeight: "normal" }}>
                              Favourite Prompts
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                  <p className="font-abadimt-extralight" style={{ color: "#001134", fontWeight: "lighter" }}>
                    {val.prompt}
                  </p>
                  <div className="text-end">
                    <button
                      className="btn bg-transparent font-md"
                      style={{ color: "#FF1635" }}
                      onClick={() => {
                        deleteFavoritePrompt(val.id);
                      }}
                    >
                      <img
                        src={require("../../assets/new-version/ion-trash.png")}
                        alt="Remove"
                        width={14}
                        className="d-inline-block"
                        style={{ verticalAlign: "middle" }}
                      />
                      <span
                        className={`d-inline-block ms-1`}
                        style={{
                          fontSize: "16px",
                          verticalAlign: "middle",
                        }}
                      >
                        Remove prompt
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
          {promptLibData.map((val, idx) => {
            return (
              <div
                key={idx}
                className={`card mx-0 default-card ${highlight === idx ? "active" : ""}`}
                onClick={() => setHighlight(idx)}
              >
                <div className="card-body">
                  <p className="font-abadimt-pronormal">
                    <div className="row mx-0 justify-content-start align-items-start">
                      <div className="col-auto p-0 me-1">
                        <span
                          className="font-abadimt-pronormal inline-block"
                          style={{
                            color: "#A1A1A1",
                            fontWeight: "normal",
                          }}
                        >
                          Related category:{" "}
                        </span>
                      </div>
                      <div className="col p-0 pe-1">
                        <div className="row mx-0 justify-content-start align-items-start font-abadimt-pronormal">
                          {val.Category.length > 0
                            ? val.Category.map((val2, idx2) => {
                                return (
                                  <div
                                    className="col-auto p-0 pe-1"
                                    key={`${val2}-${idx2}`}
                                    style={{
                                      color: "3E6CC7",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    <span className="font-highlight">
                                      {val2.Category}
                                      {idx2 + 1 != val.Category.length ? "," : ""}
                                    </span>
                                  </div>
                                );
                              })
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </p>
                  <p className="font-abadimt-extralight" style={{ color: "#001134", fontWeight: "lighter" }}>
                    {val.Prompt}
                  </p>
                </div>
              </div>
            );
          })}
        </>
      );
    } else if (isFavoriteActive && choosenCategory.length < 1) {
      return (
        <>
          {promptFavoriteData.map((val, idx) => {
            return (
              <div
                key={idx + 100}
                className={`card mx-0 default-card ${highlight === idx + 100 ? "active" : ""}`}
                onClick={() => setHighlight(idx + 100)}
              >
                <div className="card-body">
                  <p className="font-abadimt-pronormal">
                    <div className="row mx-0 justify-content-start align-items-start">
                      <div className="col-auto p-0 me-1">
                        <span
                          className="font-abadimt-pronormal inline-block"
                          style={{
                            color: "#A1A1A1",
                            fontWeight: "normal",
                          }}
                        >
                          Related category:{" "}
                        </span>
                      </div>
                      <div className="col p-0 pe-1">
                        <div className="row mx-0 justify-content-start align-items-start font-abadimt-pronormal">
                          <div className="col-auto p-0 pe-1">
                            <span className="font-highlight" style={{ color: "#3E6CC7", fontWeight: "normal" }}>
                              Favourite Prompts
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                  <p className="font-abadimt-extralight" style={{ color: "#001134", fontWeight: "lighter" }}>
                    {val.prompt}
                  </p>
                  <div className="text-end">
                    <button
                      className="btn bg-transparent font-md"
                      style={{ color: "#FF1635" }}
                      onClick={() => {
                        deleteFavoritePrompt(val.id);
                      }}
                    >
                      <img
                        src={require("../../assets/new-version/ion-trash.png")}
                        alt="Remove"
                        width={14}
                        className="d-inline-block"
                        style={{ verticalAlign: "middle" }}
                      />
                      <span
                        className={`d-inline-block ms-1`}
                        style={{
                          fontSize: "16px",
                          verticalAlign: "middle",
                        }}
                      >
                        Remove prompt
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    } else if (!isFavoriteActive && choosenCategory.length > 0) {
      return (
        <>
          {promptLibData.map((val, idx) => {
            return (
              <div
                key={idx}
                className={`card mx-0 default-card ${highlight === idx ? "active" : ""}`}
                onClick={() => setHighlight(idx)}
              >
                <div className="card-body">
                  <p className="font-abadimt-pronormal">
                    <div className="row mx-0 justify-content-start align-items-start">
                      <div className="col-auto p-0 me-1">
                        <span
                          className="font-abadimt-pronormal inline-block"
                          style={{
                            color: "#A1A1A1",
                            fontWeight: "normal",
                          }}
                        >
                          Related category:{" "}
                        </span>
                      </div>
                      <div className="col p-0 pe-1">
                        <div className="row mx-0 justify-content-start align-items-start font-abadimt-pronormal">
                          {val.Category.length > 0
                            ? val.Category.map((val2, idx2) => {
                                return (
                                  <div
                                    className="col-auto p-0 pe-1"
                                    key={`${val2}-${idx2}`}
                                    style={{
                                      color: "3E6CC7",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    <span className="font-highlight">
                                      {val2.Category}
                                      {idx2 + 1 != val.Category.length ? "," : ""}
                                    </span>
                                  </div>
                                );
                              })
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </p>
                  <p className="font-abadimt-extralight" style={{ color: "#001134", fontWeight: "lighter" }}>
                    {val.Prompt}
                  </p>
                </div>
              </div>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          {promptLibData.map((val, idx) => {
            return (
              <div
                key={idx}
                className={`card mx-0 default-card ${highlight === idx ? "active" : ""}`}
                onClick={() => setHighlight(idx)}
              >
                <div className="card-body">
                  <p className="font-abadimt-pronormal">
                    <div className="row mx-0 justify-content-start align-items-start">
                      <div className="col-auto p-0 me-1">
                        <span
                          className="font-abadimt-pronormal inline-block"
                          style={{
                            color: "#A1A1A1",
                            fontWeight: "normal",
                          }}
                        >
                          Related category:{" "}
                        </span>
                      </div>
                      <div className="col p-0 pe-1">
                        <div className="row mx-0 justify-content-start align-items-start font-abadimt-pronormal">
                          {val.Category.length > 0
                            ? val.Category.map((val2, idx2) => {
                                return (
                                  <div
                                    className="col-auto p-0 pe-1"
                                    key={`${val2}-${idx2}`}
                                    style={{
                                      color: "3E6CC7",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    <span className="font-highlight">
                                      {val2.Category}
                                      {idx2 + 1 != val.Category.length ? "," : ""}
                                    </span>
                                  </div>
                                );
                              })
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </p>
                  <p className="font-abadimt-extralight" style={{ color: "#001134", fontWeight: "lighter" }}>
                    {val.Prompt}
                  </p>
                </div>
              </div>
            );
          })}
        </>
      );
    }
  };

  const deleteFavoritePrompt = (promptId) => {
    const tokenFinal = getStorage("tokenFinal");
    const dataToDelete = {
      favourite_prompt_id: promptId,
    };

    axios
      .delete(DEFAULT_AZURE_FUNCTION_URL + "/api/Favourite_prompt?code=" + FAVORITE_PROMPT_CODE, {
        headers: {
          Authorization: "Bearer" + tokenFinal,
        },
        data: dataToDelete,
      })
      .then(() => {
        const userData = getStorage("currentLoggedInUser");
        const userEmail = userData.User_info.mail;
        const tokenFinal = getStorage("tokenFinal");

        axios
          .get(
            `${DEFAULT_AZURE_FUNCTION_URL}/api/Favourite_prompt?code=${FAVORITE_PROMPT_CODE}&user_email=${userEmail}`,
            {
              headers: {
                Authorization: "Bearer " + tokenFinal,
              },
            }
          )
          .then((res) => {
            let tmpFavData = [];

            res.data.map((val) => {
              tmpFavData.push({ id: val.favourite_prompt_id, prompt: val.prompt });
            });

            promptFavoriteDataSet(tmpFavData);
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // @ts-ignore
  useEffect(() => {
    if (getStorage("currentLoggedInUser")?.Persona !== "MOE") {
      const timeoutId = setTimeout(() => {
        setIsVisibleT2(true);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  // @ts-ignore
  useEffect(() => {
    if (getStorage("currentLoggedInUser")?.Persona !== "MOE" && appliedOption1) {
      const timeoutId = setTimeout(() => {
        setIsVisible5C(true);
      }, 4000);

      return () => clearTimeout(timeoutId);
    }
  }, [appliedOption1]);

  // @ts-ignore
  useEffect(() => {
    if (getStorage("currentLoggedInUser")?.Persona !== "MOE" && appliedOption2) {
      const timeoutId = setTimeout(() => {
        setIsVisibleLR(true);
      }, 4000);

      return () => clearTimeout(timeoutId);
    }
  }, [appliedOption2]);
  const scrollToBottomRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isVisibleT2 || isVisible5C || isVisibleLR || compilationQuestionAnswer.length) {
      scrollToBottomChatArea();
    }
  }, [isVisibleT2, isVisible5C, isVisibleLR, compilationQuestionAnswer.length]);

  const scrollToBottomChatArea = () => {
    scrollToBottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });

    setTimeout(() => {
      scrollToBottomRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 2000);
    setTimeout(() => {
      scrollToBottomRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 4000);

    scrollContainerRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  return (
    <div className="d-flex flex-column font-abadimt-prolight" style={{ minHeight: "85vh" }}>
      <div className={"d-flex justify-content-between"}>
        <h4 className={"text-default-blue fw-bold"}>Chat session</h4>
        <div
          className="d-flex align-items-center text-end gap-1 text-default-blue cursor-pointer"
          onClick={() => setResetModal(true)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <path
              d="M2.76504 0.234856C2.83535 0.305179 2.87484 0.400544 2.87484 0.499981C2.87484 0.599417 2.83535 0.694783 2.76504 0.765106L1.53017 1.99998H6.06242C8.23704 1.99998 9.99992 3.76286 9.99992 5.93748C9.99992 8.11211 8.23704 9.87498 6.06242 9.87498C3.88779 9.87498 2.12492 8.11211 2.12492 5.93748C2.12492 5.83802 2.16443 5.74264 2.23475 5.67232C2.30508 5.60199 2.40046 5.56248 2.49992 5.56248C2.59938 5.56248 2.69476 5.60199 2.76508 5.67232C2.83541 5.74264 2.87492 5.83802 2.87492 5.93748C2.87492 6.56791 3.06186 7.18418 3.41211 7.70836C3.76236 8.23254 4.26018 8.64109 4.84262 8.88235C5.42506 9.1236 6.06596 9.18672 6.68427 9.06373C7.30258 8.94074 7.87054 8.63716 8.31632 8.19138C8.7621 7.7456 9.06568 7.17765 9.18867 6.55933C9.31166 5.94102 9.24854 5.30012 9.00729 4.71768C8.76603 4.13524 8.35748 3.63742 7.8333 3.28717C7.30912 2.93692 6.69285 2.74998 6.06242 2.74998H1.53017L2.76504 3.98486C2.80086 4.01945 2.82943 4.06083 2.84908 4.10658C2.86874 4.15233 2.87908 4.20154 2.87951 4.25133C2.87995 4.30112 2.87046 4.3505 2.8516 4.39659C2.83275 4.44267 2.8049 4.48454 2.76969 4.51975C2.73448 4.55496 2.69261 4.58281 2.64653 4.60166C2.60044 4.62052 2.55106 4.63001 2.50127 4.62957C2.45148 4.62914 2.40227 4.6188 2.35652 4.59914C2.31077 4.57949 2.26939 4.55092 2.23479 4.51511L0.359795 2.64011C0.289493 2.56978 0.25 2.47442 0.25 2.37498C0.25 2.27554 0.289493 2.18018 0.359795 2.10986L2.23479 0.234856C2.30512 0.164554 2.40048 0.125061 2.49992 0.125061C2.59936 0.125061 2.69472 0.164554 2.76504 0.234856Z"
              fill="#002060"
            />
          </svg>
          <span className="font-abadimt-pronormal">Reset Chat</span>
        </div>
      </div>

      <div
        className="px-2 py-3"
        style={{ maxHeight: "44vh", overflowY: "scroll", overflowX: "hidden" }}
        ref={scrollContainerRef}
      >
        <div className="d-flex flex-column gap-3 mr-20">
          <div>
            {/* <div className={"d-flex align-items-center gap-2 mb-2 text-dgray-2"}>
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                <circle cx="5" cy="5" r="5" fill="#D95AAE" />
              </svg>
              {getStorage("currentLoggedInUser")?.User_info?.displayName}
            </div> */}
            <div className="box-chat position-relative px-0">
              <div
                className="rounded bg-another-gray border-0 p-3 font-abadimt-light pb-5 text-default-blue box-chat-callout ml-35"
                style={{ boxSizing: "border-box" }}
              >
                <div className="text-chat" dangerouslySetInnerHTML={{ __html: isiGuided1HTML }} />
              </div>

              <button
                className={`d-flex gap-1 btn-add-fav align-items-center ${
                  favorites && favorites.some((fav) => fav.key === isiGuided1 && fav.isFavorite)
                    ? "text-dblue"
                    : "grey-another-shade"
                }`}
                onClick={() => {
                  // goToGuidedPage(1);
                  // setAddtoFav(!addtoFav)
                  addPromptToFavorite(isiGuided1);
                }}
              >
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8 8" fill="none">
                  <path
                    d="M6.15559 7.5C6.10297 7.5002 6.05163 7.4838 6.00887 7.45312L3.99934 5.99624L1.98981 7.45312C1.94688 7.48426 1.89516 7.50094 1.84212 7.50075C1.78909 7.50055 1.73749 7.4835 1.69478 7.45205C1.65208 7.4206 1.62048 7.37638 1.60456 7.32579C1.58864 7.2752 1.58922 7.22086 1.60622 7.17062L2.38997 4.84921L0.358718 3.45624C0.31472 3.42611 0.281512 3.38269 0.263948 3.33233C0.246383 3.28198 0.245382 3.22732 0.261091 3.17636C0.276799 3.12539 0.308394 3.08079 0.351259 3.04906C0.394124 3.01733 0.446012 3.00013 0.499343 2.99999H3.00528L3.76153 0.672651C3.77782 0.622404 3.80961 0.578609 3.85233 0.547548C3.89506 0.516487 3.94652 0.499756 3.99934 0.499756C4.05217 0.499756 4.10363 0.516487 4.14635 0.547548C4.18908 0.578609 4.22086 0.622404 4.23716 0.672651L4.99341 3.00078H7.49934C7.55274 3.00075 7.60474 3.01782 7.64774 3.04949C7.69073 3.08116 7.72245 3.12576 7.73826 3.17677C7.75407 3.22777 7.75313 3.2825 7.73558 3.33293C7.71804 3.38336 7.6848 3.42685 7.64075 3.45703L5.60872 4.84921L6.392 7.17C6.40469 7.20757 6.40826 7.24762 6.40241 7.28685C6.39657 7.32607 6.38147 7.36335 6.35838 7.39559C6.33528 7.42783 6.30485 7.45411 6.26959 7.47226C6.23433 7.49042 6.19525 7.49992 6.15559 7.5Z"
                    fill={
                      favorites && favorites.some((fav) => fav.key === isiGuided1 && fav.isFavorite)
                        ? "#001134"
                        : "#D4D4D9"
                    }
                  />
                </svg> */}
                <svg width="15" height="15" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.55197 1.23022C5.35931 0.76701 4.70313 0.76701 4.51047 1.23022L3.41199 3.87128L0.560743 4.09986C0.0606701 4.13995 -0.142103 4.76402 0.2389 5.09039L2.41125 6.95124L1.74756 9.73357C1.63116 10.2216 2.16202 10.6073 2.59016 10.3458L5.03122 8.85476L7.47229 10.3458C7.90042 10.6073 8.43128 10.2216 8.31488 9.73357L7.65119 6.95124L9.82354 5.09039C10.2045 4.76402 10.0018 4.13995 9.5017 4.09986L6.65045 3.87128L5.55197 1.23022Z"
                    fill={
                      favorites && favorites.some((fav) => fav.key === isiGuided1 && fav.isFavorite)
                        ? "#4C506A"
                        : "#D4D4D9"
                    }
                  />
                </svg>

                {favorites && favorites.some((fav) => fav.key === isiGuided1 && fav.isFavorite)
                  ? "Added to Favourite"
                  : "Add to Favourite"}
              </button>
            </div>
          </div>

          {getStorage("currentLoggedInUser")?.Persona === "NIE" ? (
            isVisibleT2 ? (
              <>
                <div className={"chat-effect chat-font-size"}>
                  {/* <div className={"d-flex align-items-center gap-2 mb-2 text-dgray-2"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                      <circle cx="5" cy="5" r="5" fill="#00B0F0" />
                    </svg>
                    ALEX
                  </div> */}
                  <div className={"rounded text-default-blue p-3 box-chat"}>
                    Would you like your course to embody the V3Sk framework &nbsp;
                    <SpanWithTooltip
                      tooltip="The V3SK model at NIE emphasises the child as the focal point of teacher education, focusing on values, skills, and knowledge to nurture well-rounded educators for the 21st century. Aligning teaching and learning activities with the V3SK framework empowers student-teachers to make a lasting impact on their students."
                      id="tooltip-5"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 8 8" fill="none">
                        <path
                          d="M4 0.09375C1.84609 0.09375 0.09375 1.84609 0.09375 4C0.09375 6.15391 1.84609 7.90625 4 7.90625C6.15391 7.90625 7.90625 6.15391 7.90625 4C7.90625 1.84609 6.15391 0.09375 4 0.09375ZM4 1.69531C4.10044 1.69531 4.19862 1.7251 4.28213 1.78089C4.36563 1.83669 4.43072 1.916 4.46916 2.00879C4.50759 2.10158 4.51765 2.20369 4.49806 2.30219C4.47846 2.4007 4.4301 2.49118 4.35908 2.5622C4.28806 2.63322 4.19758 2.68159 4.09907 2.70118C4.00056 2.72077 3.89846 2.71072 3.80567 2.67228C3.71288 2.63385 3.63357 2.56876 3.57777 2.48525C3.52197 2.40174 3.49219 2.30356 3.49219 2.20312C3.49219 2.06844 3.54569 1.93928 3.64092 1.84405C3.73616 1.74881 3.86532 1.69531 4 1.69531ZM4.9375 6.10938H3.21875C3.13587 6.10938 3.05638 6.07645 2.99778 6.01785C2.93917 5.95924 2.90625 5.87976 2.90625 5.79688C2.90625 5.71399 2.93917 5.63451 2.99778 5.5759C3.05638 5.5173 3.13587 5.48438 3.21875 5.48438H3.76562V3.76562H3.45312C3.37024 3.76562 3.29076 3.7327 3.23215 3.6741C3.17355 3.61549 3.14062 3.53601 3.14062 3.45312C3.14062 3.37024 3.17355 3.29076 3.23215 3.23215C3.29076 3.17355 3.37024 3.14062 3.45312 3.14062H4.07812C4.16101 3.14062 4.24049 3.17355 4.2991 3.23215C4.3577 3.29076 4.39062 3.37024 4.39062 3.45312V5.48438H4.9375C5.02038 5.48438 5.09987 5.5173 5.15847 5.5759C5.21708 5.63451 5.25 5.71399 5.25 5.79688C5.25 5.87976 5.21708 5.95924 5.15847 6.01785C5.09987 6.07645 5.02038 6.10938 4.9375 6.10938Z"
                          fill="#4C506A"
                        />
                      </svg>
                    </SpanWithTooltip>{" "}
                    &nbsp; in line with the 21st-century learner's characteristics and desired outcomes?
                  </div>
                </div>

                <div className={`chat-effect chat-font-size ${appliedOption1 ? "" : "d-none"}`}>
                  <div className={"rounded bg-another-gray border-0 p-3 font-abadimt-light text-default-blue"}>
                    {appliedOption1 ? (checkedT2 ? "Yes" : "No") : ""}
                  </div>
                </div>
              </>
            ) : (
              <div className="">
                <div className=" box-chat rounded text-default-blue p-3">
                  <>
                    I'm thinking <span className="loading-dots">...</span>
                  </>
                </div>
              </div>
            )
          ) : null}

          {getStorage("currentLoggedInUser")?.Persona === "NIE" ? (
            appliedOption1 ? (
              isVisible5C ? (
                <>
                  <div className={"chat-effect chat-font-size"}>
                    <div className={"box-chat rounded text-default-blue p-3"}>
                      Would you like your course to embody the 5C’s framework &nbsp;
                      <SpanWithTooltip
                        tooltip="The 5C's of Graduate Attributes encompass Character, Competence, Creativity, Communication, and Civic-mindedness, emphasising holistic student development in the areas of personal qualities, knowledge, innovative thinking, effective communication, and community engagement."
                        id="tooltip-6"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 8 8" fill="none">
                          <path
                            d="M4 0.09375C1.84609 0.09375 0.09375 1.84609 0.09375 4C0.09375 6.15391 1.84609 7.90625 4 7.90625C6.15391 7.90625 7.90625 6.15391 7.90625 4C7.90625 1.84609 6.15391 0.09375 4 0.09375ZM4 1.69531C4.10044 1.69531 4.19862 1.7251 4.28213 1.78089C4.36563 1.83669 4.43072 1.916 4.46916 2.00879C4.50759 2.10158 4.51765 2.20369 4.49806 2.30219C4.47846 2.4007 4.4301 2.49118 4.35908 2.5622C4.28806 2.63322 4.19758 2.68159 4.09907 2.70118C4.00056 2.72077 3.89846 2.71072 3.80567 2.67228C3.71288 2.63385 3.63357 2.56876 3.57777 2.48525C3.52197 2.40174 3.49219 2.30356 3.49219 2.20312C3.49219 2.06844 3.54569 1.93928 3.64092 1.84405C3.73616 1.74881 3.86532 1.69531 4 1.69531ZM4.9375 6.10938H3.21875C3.13587 6.10938 3.05638 6.07645 2.99778 6.01785C2.93917 5.95924 2.90625 5.87976 2.90625 5.79688C2.90625 5.71399 2.93917 5.63451 2.99778 5.5759C3.05638 5.5173 3.13587 5.48438 3.21875 5.48438H3.76562V3.76562H3.45312C3.37024 3.76562 3.29076 3.7327 3.23215 3.6741C3.17355 3.61549 3.14062 3.53601 3.14062 3.45312C3.14062 3.37024 3.17355 3.29076 3.23215 3.23215C3.29076 3.17355 3.37024 3.14062 3.45312 3.14062H4.07812C4.16101 3.14062 4.24049 3.17355 4.2991 3.23215C4.3577 3.29076 4.39062 3.37024 4.39062 3.45312V5.48438H4.9375C5.02038 5.48438 5.09987 5.5173 5.15847 5.5759C5.21708 5.63451 5.25 5.71399 5.25 5.79688C5.25 5.87976 5.21708 5.95924 5.15847 6.01785C5.09987 6.07645 5.02038 6.10938 4.9375 6.10938Z"
                            fill="#4C506A"
                          />
                        </svg>
                      </SpanWithTooltip>
                      &nbsp; in line with the 21st-century learner's characteristics and desired outcomes?
                    </div>
                  </div>

                  <div className={`chat-effect chat-font-size ${appliedOption2 ? "" : "d-none"}`}>
                    <div className={"rounded bg-another-gray border-0 p-3 font-abadimt-light text-default-blue"}>
                      {appliedOption2 ? (checked5C ? "Yes" : "No") : ""}
                    </div>
                  </div>
                </>
              ) : (
                <div className="chat-effect-loading">
                  {/* <div className="d-flex align-items-center gap-2 mb-2 text-dgray-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                      <circle cx="5" cy="5" r="5" fill="#00B0F0" />
                    </svg>
                    ALEX
                  </div> */}

                  <div className="box-chat rounded text-default-blue p-3">
                    <>
                      I'm thinking <span className="loading-dots">...</span>
                    </>
                  </div>
                </div>
              )
            ) : null
          ) : null}

          {getStorage("currentLoggedInUser")?.Persona === "NIE" ? (
            appliedOption2 ? (
              isVisibleLR ? (
                <>
                  <div className={"chat-effect chat-font-size"}>
                    {/* <div className={"d-flex align-items-center gap-2 mb-2 text-dgray-2"}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <circle cx="5" cy="5" r="5" fill="#00B0F0" />
                      </svg>
                      ALEX
                    </div> */}
                    <div className={"box-chat rounded text-default-blue p-3"}>
                      May I suggest additional resources, such as articles, books, or videos, to supplement your
                      teaching materials?
                    </div>
                  </div>

                  <div className={`chat-effect chat-font-size ${appliedOption3 ? "" : "d-none"}`}>
                    <div className={"rounded bg-another-gray border-0 p-3 font-abadimt-light text-default-blue"}>
                      {appliedOption3 ? (checkedLR ? "Yes" : "No") : ""}
                    </div>
                  </div>
                </>
              ) : (
                <div className="chat-effect-loading">
                  {/* <div className="d-flex align-items-center gap-2 mb-2 text-dgray-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                      <circle cx="5" cy="5" r="5" fill="#00B0F0" />
                    </svg>
                    ALEX
                  </div> */}

                  <div className="box-chat rounded text-default-blue p-3">
                    <>
                      I'm thinking <span className="loading-dots">...</span>
                    </>
                  </div>
                </div>
              )
            ) : null
          ) : null}

          {compilationQuestionAnswer.map((data, index) => {
            if (Array.isArray(data)) {
              return (
                <div className={`box-chat chat-box`} key={index}>
                  {data.map((data2, index2) => {
                    if (data2?.hasOwnProperty("error")) {
                      return (
                        <div key={index + "_" + index2}>
                          {/* <div className={"d-flex align-items-center gap-2 mb-2 text-dgray-2"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                            >
                              <circle cx="5" cy="5" r="5" fill="#00B0F0" />
                            </svg>
                            ALEX
                          </div> */}

                          <div className={"box-chat rounded text-default-blue p-3 mt-3"}>{data2?.error}</div>
                        </div>
                      );
                    } else if (data2?.hasOwnProperty("chat")) {
                      return (
                        <div key={"aa_" + index + "_" + index2}>
                          {/* <div className={"d-flex align-items-center gap-2 mb-2 text-dgray-2"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                            >
                              <circle cx="5" cy="5" r="5" fill="#00B0F0" />
                            </svg>
                            ALEX
                          </div> */}

                          <div className={"box-chat rounded text-default-blue mt-3"}>
                            {/* {data2?.chat} */}

                            {data2?.chat.split("\n").map((content) => {
                              if (content.length > 0) {
                                return <p>{content}</p>;
                              } else {
                                return "";
                              }
                            })}
                          </div>

                          {data2?.references?.length > 0 ? (
                            <div className={"bg-ltblue rounded text-dblue p-3 mt-3"} key={index2}>
                              {isConv ? null : (
                                <p>
                                  With our combined efforts, your{" "}
                                  {getStorage("currentLoggedInUser")?.Persona == "MOE"
                                    ? "lesson plan "
                                    : "course outline "}
                                  has been transferred to Word.
                                </p>
                              )}
                              Keen to explore the resources I have referenced to answer your question?
                              <ul style={{ wordWrap: "break-word" }}>
                                {data2?.references?.map((reference, index3) => {
                                  return (
                                    <li key={index + "_" + index2 + "_" + index3}>
                                      <a
                                        rel="noreferrer"
                                        className="font-action-hightlight"
                                        href={reference["Blob_Url"]}
                                        target="_blank"
                                      >
                                        {reference["File name"] == "" ? reference["Blob_Url"] : reference["File name"]}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <div key={"cc_" + index + "_" + index2}>
                          {/* <div className={"d-flex align-items-center gap-2 mb-2 text-dgray-2"}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                            >
                              <circle cx="5" cy="5" r="5" fill="#00B0F0" />
                            </svg>
                            ALEX
                          </div> */}

                          {data2?.Reference_explanation?.length > 0 ? (
                            <div className={"box-chat rounded text-default-blue mt-3"} key={index2}>
                              <div className="p-3">
                                {isConv ? null : (
                                  <p>
                                    With our combined efforts, your{" "}
                                    {getStorage("currentLoggedInUser")?.Persona == "MOE"
                                      ? "lesson plan "
                                      : "course outline "}
                                    has been transferred to Word.
                                  </p>
                                )}
                                <p>I have referenced the following sources to co-create the outline for you.</p>
                              </div>
                              <div className="accordion mt-4" id="accordionData">
                                {data2?.Reference_explanation?.map((entry, index) => (
                                  <div className="accordion-item" key={index}>
                                    <h2 className="accordion-header" id={`heading${index}`}>
                                      <button
                                        className="accordion-button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${index}`}
                                        aria-expanded="true"
                                        aria-controls={`collapse${index}`}
                                      >
                                        {entry["File name"]}.
                                      </button>
                                    </h2>
                                    <div
                                      id={`collapse${index}`}
                                      className={`accordion-collapse collapse`}
                                      aria-labelledby={`heading${index}`}
                                      data-bs-parent="#accordionData"
                                    >
                                      <div className="accordion-body">
                                        {entry["Explanation"]}
                                        <p className="mt-4">
                                          <a
                                            rel="noreferrer"
                                            className="font-action-hightlight"
                                            href={entry["Blob_Url"]}
                                            target="_blank"
                                          >
                                            {entry["File name"]}.
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {/* <ul>
                                {data2?.Reference_explanation?.map((entry, index) => (
                                  <li key={index}>
                                    <a className="font-action-hightlight" href={entry["Blob_Url"]} target="_blank">
                                      [{entry["File name"]}].
                                    </a>
                                    {entry["Explanation"]}
                                  </li>
                                ))}
                              </ul> */}
                            </div>
                          ) : null}

                          {data2?.Conclusion ? (
                            <div className={"box-chat rounded text-default-blue p-3 mt-3"} key={data2?.Conclusion}>
                              {/* {data2.Conclusion} */}

                              {data2.Conclusion.split("\n").map((content) => {
                                if (content.length > 0) {
                                  return (
                                    <Typewriter
                                      onInit={(typewriter) => {
                                        typewriter
                                          .callFunction(() => {
                                            scrollToBottomChatArea();
                                          })
                                          .typeString(content)
                                          .changeDelay(1)
                                          .callFunction(() => {
                                            scrollToBottomChatArea();
                                          })
                                          .pauseFor(2000)
                                          .callFunction(() => {
                                            scrollToBottomChatArea();
                                          })

                                          .start();
                                      }}
                                      options={{
                                        delay: 1,
                                        skipAddStyles: false,
                                        cursorClassName: "d-none",
                                      }}
                                    />
                                  );
                                } else {
                                  return "";
                                }
                              })}
                            </div>
                          ) : null}

                          {data2.Reflective_repsonce ? (
                            <div
                              className={"box-chat rounded text-default-blue p-3 mt-3"}
                              key={data2.Reflective_repsonce}
                            >
                              {data2.Reflective_repsonce.split("\n").map((content) => {
                                if (content.length > 0) {
                                  return (
                                    <Typewriter
                                      onInit={(typewriter) => {
                                        typewriter
                                          .pauseFor(12000)
                                          .callFunction(() => {
                                            scrollToBottomChatArea();
                                          })

                                          .typeString(content)
                                          .changeDelay(1)
                                          .pauseFor(2000)
                                          .callFunction(() => {
                                            scrollToBottomChatArea();
                                          })
                                          .pauseFor(2000)
                                          .callFunction(() => {
                                            scrollToBottomChatArea();
                                          })
                                          .pauseFor(2000)
                                          .callFunction(() => {
                                            scrollToBottomChatArea();
                                          })
                                          .start();
                                      }}
                                      options={{
                                        delay: 20,
                                        skipAddStyles: false,
                                        cursorClassName: "d-none",
                                      }}
                                    />
                                  );
                                } else {
                                  return "";
                                }
                              })}
                            </div>
                          ) : null}
                        </div>
                      );
                    }
                  })}
                </div>
              );
            } else {
              return (
                <>
                  {data ? (
                    <div key={"dd_" + index}>
                      {/* <div className={"d-flex align-items-center gap-2 mb-2 text-dgray-2"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                          <circle cx="5" cy="5" r="5" fill="#D95AAE" />
                        </svg>
                        {getStorage("currentLoggedInUser")?.User_info?.displayName}
                      </div> */}
                      <div
                        className={`position-relative rounded bg-another-gray border-0 p-3 font-abadimt-light pb-5 text-default-blue`}
                        style={{ wordWrap: "break-word" }}
                      >
                        {data}

                        <button
                          className={`d-flex gap-1 btn-add-fav align-items-center ${
                            favorites && favorites.some((fav) => fav.key === data && fav.isFavorite)
                              ? "text-dblue"
                              : "grey-another-shade"
                          }`}
                          onClick={() => {
                            // goToGuidedPage(1);
                            // setAddtoFav(!addtoFav)
                            addPromptToFavorite(data);
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8 8" fill="none">
                            <path
                              d="M6.15559 7.5C6.10297 7.5002 6.05163 7.4838 6.00887 7.45312L3.99934 5.99624L1.98981 7.45312C1.94688 7.48426 1.89516 7.50094 1.84212 7.50075C1.78909 7.50055 1.73749 7.4835 1.69478 7.45205C1.65208 7.4206 1.62048 7.37638 1.60456 7.32579C1.58864 7.2752 1.58922 7.22086 1.60622 7.17062L2.38997 4.84921L0.358718 3.45624C0.31472 3.42611 0.281512 3.38269 0.263948 3.33233C0.246383 3.28198 0.245382 3.22732 0.261091 3.17636C0.276799 3.12539 0.308394 3.08079 0.351259 3.04906C0.394124 3.01733 0.446012 3.00013 0.499343 2.99999H3.00528L3.76153 0.672651C3.77782 0.622404 3.80961 0.578609 3.85233 0.547548C3.89506 0.516487 3.94652 0.499756 3.99934 0.499756C4.05217 0.499756 4.10363 0.516487 4.14635 0.547548C4.18908 0.578609 4.22086 0.622404 4.23716 0.672651L4.99341 3.00078H7.49934C7.55274 3.00075 7.60474 3.01782 7.64774 3.04949C7.69073 3.08116 7.72245 3.12576 7.73826 3.17677C7.75407 3.22777 7.75313 3.2825 7.73558 3.33293C7.71804 3.38336 7.6848 3.42685 7.64075 3.45703L5.60872 4.84921L6.392 7.17C6.40469 7.20757 6.40826 7.24762 6.40241 7.28685C6.39657 7.32607 6.38147 7.36335 6.35838 7.39559C6.33528 7.42783 6.30485 7.45411 6.26959 7.47226C6.23433 7.49042 6.19525 7.49992 6.15559 7.5Z"
                              fill={
                                favorites && favorites.some((fav) => fav.key === data && fav.isFavorite)
                                  ? "#001134"
                                  : "#D4D4D9"
                              }
                            />
                          </svg>
                          {favorites && favorites.some((fav) => fav.key === data && fav.isFavorite)
                            ? "Added to Favourite"
                            : "Add to Favourite"}
                        </button>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            }
          })}

          {isLoading ? (
            <div>
              {/* <div className="d-flex align-items-center gap-2 mb-2 text-dgray-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <circle cx="5" cy="5" r="5" fill="#00B0F0" />
                </svg>
                ALEX
              </div> */}

              <div className="chat-effect-loading box-chat rounded text-default-blue p-3 mt-3">
                {isConv ? (
                  <>
                    I'm thinking <span className="loading-dots">...</span>
                  </>
                ) : (
                  <>
                    Retrieving information<span className="loading-dots">...</span>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>
        <div ref={scrollToBottomRef}></div>
      </div>

      <div className="mt-auto">
        <div className={`px-3 py-4 text-center ${displayHighlightedText == "" ? "" : "d-none"}`}>
          {isDisplayInformation ? (
            <>
              <svg width="26" height="24" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.16948 2.10001C4.34694 2.10001 2.86948 3.57747 2.86948 5.40001C2.86948 7.22255 4.34694 8.70001 6.16948 8.70001C7.08088 8.70001 7.90526 8.33114 8.50294 7.73347C9.10061 7.13579 9.46948 6.31141 9.46948 5.40001C9.46948 3.57747 7.99202 2.10001 6.16948 2.10001ZM1.96948 5.40001C1.96948 3.08042 3.84989 1.20001 6.16948 1.20001C8.48908 1.20001 10.3695 3.08042 10.3695 5.40001C10.3695 6.39753 10.0213 7.31435 9.44052 8.03465L11.4377 10.0318C11.6134 10.2076 11.6134 10.4925 11.4377 10.6682C11.2619 10.8439 10.977 10.8439 10.8013 10.6682L8.80412 8.67105C8.08382 9.25183 7.167 9.60001 6.16948 9.60001C3.84989 9.60001 1.96948 7.71961 1.96948 5.40001Z"
                  fill="#808899"
                />
              </svg>
              <p className="mt-2">Rollover and highlight texts in document on left to ask ALEX</p>
            </>
          ) : (
            ""
          )}
        </div>

        <form
          onSubmit={handleSubmit}
          className={`${
            isWaitingResponse ? "disabled" : ""
          } frm-chat-step2 box-chat-callout bg-another-gray position-relative`}
        >
          {/* Hightlight text */}
          {appliedOption1 && (
            <div className={`${displayHighlightedText == "" ? "d-none" : ""}`}>
              <div
                className={`py-1 bg-another-gray-2 ${
                  displayHighlightedText !== "" ? "text-default-grey" : "text-dgray"
                }`}
                style={{ fontSize: "13px" }}
              >
                {displayHighlightedText !== "" ? displayHighlightedText : ""}
              </div>
            </div>
          )}
          {/* End Hightlight text */}
          <div
            className={`p-relative d-flex justify-content-between wrapper-input-chat align-items-center 
              ${appliedOption1 && appliedOption2 && appliedOption3 ? "" : "d-none"} 
              ${prompt ? "active" : ""}`}
          >
            <textarea
              className={`text-dblue input-chat`}
              rows={promptFE?.length > 50 ? 3 : 1}
              onChange={handleChangeKonten}
              disabled={isWaitingResponse ? true : false}
              onKeyDown={handleKeyDown}
              placeholder="Ask ALEX"
              autoFocus
              value={promptFE}
            />{" "}
            <button type="submit" className="btn-transparent">
              <svg
                className="submit-normal"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.168 7.5L8.16797 5.5M8.16797 5.5L6.16797 7.5M8.16797 5.5L8.16797 10.5M14.168 8C14.168 11.3137 11.4817 14 8.16797 14C4.85426 14 2.16797 11.3137 2.16797 8C2.16797 4.68629 4.85426 2 8.16797 2C11.4817 2 14.168 4.68629 14.168 8Z"
                  stroke="#0F172A"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <svg
                className="submit-active"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.67969 1.6001C5.08984 1.6001 2.17969 4.51025 2.17969 8.1001C2.17969 11.6899 5.08984 14.6001 8.67969 14.6001C12.2695 14.6001 15.1797 11.6899 15.1797 8.1001C15.1797 4.51025 12.2695 1.6001 8.67969 1.6001ZM9.03324 5.24654C8.93947 5.15278 8.8123 5.1001 8.67969 5.1001C8.54708 5.1001 8.4199 5.15278 8.32613 5.24654L6.32613 7.24654C6.13087 7.44181 6.13087 7.75839 6.32613 7.95365C6.5214 8.14891 6.83798 8.14891 7.03324 7.95365L8.17969 6.8072L8.17969 10.6001C8.17969 10.8762 8.40354 11.1001 8.67969 11.1001C8.95583 11.1001 9.17969 10.8762 9.17969 10.6001L9.17969 6.8072L10.3261 7.95365C10.5214 8.14891 10.838 8.14891 11.0332 7.95365C11.2285 7.75839 11.2285 7.44181 11.0332 7.24654L9.03324 5.24654Z"
                  fill="#00B0F0"
                />
              </svg>
            </button>
          </div>

          <div
            className={`d-flex gap-2 align-items-center wrapper-yes-no ${
              appliedOption1 && appliedOption2 && appliedOption3 ? "d-none" : ""
            } `}
          >
            (
            <>
              <button
                className="btn-transparent p-0"
                onClick={() => {
                  if (appliedOption1 == false && appliedOption2 == false && appliedOption3 == false) {
                    setAppliedOption1(true);
                    setCheckedT2(true);
                  }

                  if (appliedOption2 == false && appliedOption1 && appliedOption3 == false) {
                    setAppliedOption2(true);
                    setChecked5C(true);
                  }

                  if (appliedOption3 == false && appliedOption1 && appliedOption2) {
                    setAppliedOption3(true);
                    setCheckedLR(true);
                  }
                }}
              >
                Yes
              </button>{" "}
              <div>/</div>
              <button
                className="btn-transparent p-0"
                onClick={() => {
                  if (appliedOption1 == false && appliedOption2 == false && appliedOption3 == false) {
                    setAppliedOption1(true);
                    setCheckedT2(false);
                  }

                  if (appliedOption2 == false && appliedOption1 && appliedOption3 == false) {
                    setAppliedOption2(true);
                    setChecked5C(false);
                  }

                  if (appliedOption3 == false && appliedOption1 && appliedOption2) {
                    setAppliedOption3(true);
                    setCheckedLR(false);
                  }
                }}
              >
                No
              </button>
            </>
            )
          </div>
        </form>

        <div className="d-flex justify-content-between ">
          <SpanWithTooltip
            tooltip="Access the Prompt Library to explore structured prompts that can guide your questions effectively."
            id="tooltip-1"
          >
            <button
              onClick={() => {
                isWaitingResponse ? "" : setIsShowModal(true);
              }}
              className={`btn-polos text-dblue ${isWaitingResponse ? "disabled" : ""}`}
            >
              <u>Not sure what to ask?</u>
            </button>
          </SpanWithTooltip>
          {/* <button
                  type={"submit"}
                  className={`btn-dblue ${buttonStatus ? "" : "disabled"} rounded-pill py-1 px-3`}
                >
                  Submit
                </button> */}
        </div>
      </div>

      <Modal centered show={resetModal} onHide={() => setResetModal(false)} className="need-margin">
        <Modal.Body>
          <div className="text-center">
            <h5>
              <b className="font-abadimt-probold text-default-blue">Reset the Chat?</b>
            </h5>
            <p className="font-abadimt-light text-default-blue font-md mb-3">
              Hitting that reset chat button will wipe out our current conversations. Confirm the action?
            </p>

            <div className="d-flex gap-3 justify-content-center font-abadimt-prolight">
              <div className="col text-end">
                <button className={"btn-custom-2 rounded-pill"} onClick={() => setResetModal(false)}>
                  Cancel
                </button>
              </div>
              <div className="col text-start">
                <button
                  className={"btn-custom-2 rounded-pill"}
                  onClick={() => {
                    resetChat();
                    handleClick("");
                    setCompilationQuestionAnswer([]);
                  }}
                >
                  Reset chat
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={isShowModal} onHide={handleClose} className="modal-bottom">
        <Modal.Body
          className={"p-5 overflow-y-auto position-relative"}
          style={{ maxHeight: "70vh", minHeight: "70vh", minWidth: "75vw" }}
        >
          <button className={"btn-polos btn-x-modal"} type={"button"} onClick={() => setIsShowModal(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 6 7" fill="none">
              <path
                d="M3 4.13878L0.764258 6.37452C0.680608 6.45817 0.574144 6.5 0.444867 6.5C0.315589 6.5 0.209125 6.45817 0.125475 6.37452C0.0418249 6.29087 0 6.18441 0 6.05513C0 5.92586 0.0418249 5.81939 0.125475 5.73574L2.36122 3.5L0.125475 1.26426C0.0418249 1.18061 0 1.07414 0 0.944867C0 0.815589 0.0418249 0.709125 0.125475 0.625475C0.209125 0.541825 0.315589 0.5 0.444867 0.5C0.574144 0.5 0.680608 0.541825 0.764258 0.625475L3 2.86122L5.23574 0.625475C5.31939 0.541825 5.42586 0.5 5.55513 0.5C5.68441 0.5 5.79087 0.541825 5.87452 0.625475C5.95817 0.709125 6 0.815589 6 0.944867C6 1.07414 5.95817 1.18061 5.87452 1.26426L3.63878 3.5L5.87452 5.73574C5.95817 5.81939 6 5.92586 6 6.05513C6 6.18441 5.95817 6.29087 5.87452 6.37452C5.79087 6.45817 5.68441 6.5 5.55513 6.5C5.42586 6.5 5.31939 6.45817 5.23574 6.37452L3 4.13878Z"
                fill="#002060"
              />
            </svg>
          </button>
          <h5 className="">
            <b>Prompt Library</b>
          </h5>

          <Accordion activeKey={activeKey}>
            <Accordion.Item eventKey="0" className={"custom-accordion"}>
              <Accordion.Header
                onClick={() => handleItemClick("0")}
                className="custom-link-2 font-abadimt-extralight mb-3"
              >
                What is 'Prompt'?
              </Accordion.Header>
              <Accordion.Body className={"position-relative mb-3"}>
                <button className={"btn-polos btn-x"} type={"button"} onClick={() => handleCloseButtonClick()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 4 4" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.36724 1.89787L3.73449 3.2655L3.99999 3.00038L2.63236 1.63275L3.99999 0.2655L3.73486 0L2.36724 1.36763L0.999988 0L0.734863 0.2655L2.10211 1.63275L0.734863 3L0.999988 3.2655L2.36724 1.89787Z"
                      fill="#7C7C7C"
                    />
                  </svg>
                </button>
                <p className={"mb-0 font-abadimt-light"}>
                  A "prompt" is like a question or a request that you give to ALEX to ask it to do something or give you
                  some information. It's a way to tell ALEX what you want it to do or what kind of answer you're looking
                  for.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Select
            closeMenuOnSelect={false}
            isMulti
            name="options"
            options={categoryOption}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => onChoosenCategoryChanged(e)}
          />

          <div className={"my-3"}>
            <ShowedPromptCount />
          </div>

          <div className={"d-flex flex-column gap-3"}>
            <PromptLibList />
          </div>
        </Modal.Body>
        <Modal.Footer className={"justify-content-center"}>
          <button
            onClick={() => onUsePrompt()}
            className={`d-flex gap-2 btn-custom-2 rounded-pill align-items-center justify-content-center`}
            style={{ width: "fit-content" }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
              <path
                d="M8.825 0.125H1.175C0.9375 0.125 0.75 0.325 0.75 0.55V5.7125C0.75 5.95 0.9375 6.1375 1.175 6.1375H5.85L7.55 7.875V6.15H8.825C9.0625 6.15 9.25 5.95 9.25 5.725V0.5625C9.25 0.325 9.0625 0.125 8.825 0.125Z"
                fill="#002060"
              />
            </svg>
            Use Prompt
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
