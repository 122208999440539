/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, {useEffect, useState} from "react";
import Footer from "../Footer";
import Button from "../utility/Button";
import { PublicClientApplication } from "@azure/msal-browser";
import { dialogFallback } from "../../../helpers/fallbackauthdialog";

const logo = require('../../assets/alex-logo-cropped.png');

export default function Login({ routerHandler }) {
    const msalConfig = {
        auth: {
            clientId: "8331f048-3621-4d22-a3ca-183db9d4a75c",
            authority: "https://login.microsoftonline.com/667439c9-20b5-4283-bd7b-fb6b3099d221",
            redirectUri: "https://localhost:3000/taskpane.html",
        },
    };

    const msalInstance = new PublicClientApplication(msalConfig);

    const [showButton, setShowButton] = useState(true)
    const [timeLeft, setTimeLeft] = useState(0)

    const routerChange = (value) => {
        routerHandler(value);
    }

    useEffect(() => {
        login();

    })

    const onRefreshPage = async () => {
        window.location.reload();
    }
    
  
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const login = async () => {
        try {
            dialogFallback('yes')

        } catch (error) {
            console.log(error);

            if (error.code) {
                dialogFallback("yes");
            }
            // Handle login error.
        }
    };

    return (
        <>
            <div className="login row mx-0 px-4 h-100 justify-center text-center">
                <div className="col-12 p-0 my-auto text-center">
                    <img src={logo} alt="Logo" className="block mb-4" />
                    <p className="font-abadimt-extralight font-md">Processing Authentication...</p>
                    {
                        showButton ? 
                        <button className="gap-2 py-2 btn-custom-login rounded-pill align-items-center justify-content-center" onClick={() => onRefreshPage()}>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" className="d-inline-block me-2">
                                <path d="M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z" />
                            </svg> */}
                            Refresh Page
                        </button> 
                        : 
                        false
                        // `Waiting for authentication, if application is not authenticated in ${timeLeft} seconds, kindly press the button show after timer end.`
                    }
                </div>
            </div>
            <Footer routerHandler={() => {}} className="fixed-bottom text-center" />
        </>
    )
}