/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from "react";

export default function Footer({ routerHandler, className = "" }) {
    const pageChange = (value) => {
        routerHandler(value);
    }

    return (
        <footer className={`${className} cursor-pointer`} style={{ marginTop: '67px' }} onClick={() => pageChange('about')}>
            <div>
                <img src={require("./../../../assets/alex-footer-logo.png")} alt="logo footer" />
            </div>
            <div>
                <p className="font-abadimt-light fw-light font-sm">ALEX is a product by the National Institute of Education, Singapore</p>
            </div>
        </footer>
    );
}