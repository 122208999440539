/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { Navbar } from "../Navbar";
import Button from "../utility/Button";
import { getStorage, setStorage, removeStorage } from "../../../helpers/utils";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

import { DEFAULT_AZURE_FUNCTION_URL, STORE_USER_INFO_CODE, CMS_USER_LIST_CODE } from "../../../environment";

export default function Start({ routerHandler, onBoardingPageChange }) {
  const [showPrivacyModal, showPrivacyModalSet] = useState(false);

  const pageChange = (value) => {
    onBoardingPageChange(value);
  };

  const routerChange = (value) => {
    routerHandler(value);
  };

  const updateOnboardingPass = (isConsent = false) => {
    const tokenFinal = getStorage("tokenFinal");
    const currentUserData = getStorage("currentLoggedInUser");
    if (tokenFinal) {
      axios
        .get("https://graph.microsoft.com/v1.0/me", {
          headers: {
            Authorization: "Bearer " + tokenFinal,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            axios
              .post(
                `${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_Store_User_Info?code=${STORE_USER_INFO_CODE}`,
                {
                  user_data: res.data,
                  persona: currentUserData.Persona,
                  custom_instruction: currentUserData.Custom_instruction,
                  is_pass_onboarding: true,
                  is_consent: isConsent,
                },
                {
                  headers: {
                    Authorization: "Bearer" + tokenFinal,
                  },
                }
              )
              .then((res2) => {
                setStorage("currentLoggedInUser", res2.data.user_information[0]);
                routerChange("");
              });
          }
        })
        .catch((err) => {
          removeStorage("currentLoggedInUser");
          removeStorage("Office API client");
          removeStorage("tokenFinal");
          removeStorage("bearerToken");
          window.location.reload();
          console.error(err);
        });
    }
  };

  return (
    <Navbar routerHandler={routerChange} inChatPage={true}>
      <div className="greeting row mx-0 justify-center text-center px-4 overflow-x-hidden">
        <div className="col-12 p-0 my-auto">
          <h3 className="font-abadimt-probold text-default-blue font-lg mb-4">
            No Worries, You Can Always Personalise Later!
          </h3>
          <p className="font-abadimt-light font-md mb-4 text-default-blue">
            Feel free to explore ALEX as is. You can personalise your experience anytime in 'Settings' &gt; 'Custom
            Instructions.' I'm here whenever you're ready. Happy exploring!
          </p>
        </div>
        <div className="col-12 p-0 mb-3 text-center">
          <Button
            className="font-abadimt-prolight font-md"
            text="Start ALEX"
            onClick={() => showPrivacyModalSet(true)}
          />
        </div>
      </div>
      <Modal show={showPrivacyModal} centered backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="text-center px-4">
            <h5>
              <b className="font-abadimt-probold text-default-blue font-lg">Permission for Interaction Tracking</b>
            </h5>
            <p className="font-abadimt-light text-default-blue font-md">
              Please let us know if you allow ALEX to record your interactions. This will be used for research purposes.
            </p>

            <div className="d-flex gap-3 justify-content-center font-abadimt-prolight">
              <div className="col text-end">
                <button
                  className={"btn-custom-2 rounded-pill font-abadimt-prolight font-md"}
                  onClick={() => {
                    updateOnboardingPass(false);
                  }}
                >
                  Decline
                </button>
              </div>
              <div className="col text-start">
                <button
                  className={"btn-custom-2 rounded-pill font-abadimt-prolight font-md"}
                  onClick={() => {
                    updateOnboardingPass(true);
                  }}
                >
                  Allow
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
}
