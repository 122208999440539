import React, { useEffect, useState } from "react";
import Guided2 from "./StepTwo";
import { Modal } from "react-bootstrap";
import { getStorage } from "../../../helpers/utils";

export default function StepOne(props) {
  const {
    onPageChange,
    questionToBeAsked,
    onUpdateQuestionToBeAsked,
    onChangeIsTE21,
    onChangeIsLearningResource,
    onChangeIs5C,
    onChangeisNavigationComingFromCourseDesign,
    isNavigationComingFromCourseDesign,
    isTE21,
    isLearningResource,
    is5C,
    onChangeAnswerResult,
    answerResult,
    loading,
    onChangeLoadingState,
    onChangeTypeGeneration,
    typeGeneration,
    highlightedText,
    onChangeModalOptions,
    isShowModalOptions,
    guidedCoursePage,
    onChangeGuidedCoursePage,
    isEvaluated,
    onChangeIsEvaluated,
    evaluationAnswer,
    onChangeEvaluationAnswer,
    submitChat,
    chatResponse,
    isiKonten,
    setIsiKonten,
    textingFunc,
    uuid,
    onResetSession,
  } = props;

  const [isiGuided1, setIsiGuided1] = useState("");
  const [isiGuided1HTML, setIsiGuided1HTML] = useState("");
  const [resetModal, setResetModal] = useState(false);
  const [currentPersona, setCurrentPersona] = useState("");

  // NIE
  const [numberOfLesson, setNumberOfLesson] = useState("");
  const [courseTitle, setCourseTitle] = useState("");

  // MOE
  const [durationInMins, setDurationInMins] = useState("");
  const [studentLevel, setStudentLevel] = useState("");
  const [topics, setTopics] = useState("");

  const goToGuidedPage = (page) => {
    onChangeGuidedCoursePage(page);
    // setPage(page);
  };

  const handleClick = (isi) => {
    onPageChange(isi);
  };

  const onSetIsTE21 = async (isTrue) => {
    onChangeIsTE21(isTrue);
  };

  const onSetIsLearningResource = async (isTrue) => {
    onChangeIsLearningResource(isTrue);
  };

  const onSetIs5C = async (isTrue) => {
    onChangeIs5C(isTrue);
  };

  const handleSubmitChat = () => {
    submitChat();
  };

  const handleSetIsiKonten = (text) => {
    setIsiKonten(text);
  };

  const handleChangeDuration = (e) => {
    const updatedValue = e.target.value;
    setDurationInMins(updatedValue);
  };
  const handleChangeStudentLevel = (e) => {
    const updatedValue = e.target.value;
    setStudentLevel(updatedValue);
  };
  const handleChangeTopics = (e) => {
    const updatedValue = e.target.value;
    setTopics(updatedValue);
  };
  const handleChangeNumberOfLesson = (e) => {
    const updatedValue = e.target.value;
    setNumberOfLesson(updatedValue);
  };
  const handleChangeCourseTitle = (e) => {
    const updatedValue = e.target.value;
    setCourseTitle(updatedValue);
  };

  //   const onClickButtonStep2 = () => {
  //     if (currentPersona == "MOE") {
  //       setIsiGuided1(
  //         "Write me a " +
  //           durationInMins +
  //           " minutes lesson plan for s" +
  //           studentLevel +
  //           " students on " +
  //           topics +
  //           ""
  //       );
  //       goToGuidedPage(2);
  //     } else {
  //       setIsiGuided1("Write me a " + numberOfLesson + " week(s) course outline on " + courseTitle + ".");
  //       goToGuidedPage(2);
  //     }
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (currentPersona == "MOE") {
      setIsiGuided1(
        "Write me a " + durationInMins + " minutes lesson plan for " + studentLevel + " students on " + topics + ""
      );
      setIsiGuided1HTML(
        "Write me a <u class='bold-600'>" +
          durationInMins +
          "</u> minutes lesson plan for <u class='bold-600'>" +
          studentLevel +
          "</u> students on <u class='bold-600'>" +
          topics +
          "</u>"
      );
      goToGuidedPage(2);
    } else {
      setIsiGuided1("Write me a " + numberOfLesson + " week(s) course outline on " + courseTitle + ".");
      setIsiGuided1HTML(
        "Write me a <u class='bold-600'>" +
          numberOfLesson +
          "</u> week(s) course outline on <u class='bold-600'>" +
          courseTitle +
          "</u>."
      );
      goToGuidedPage(2);
    }
  };

  const resetChat = () => {
    setIsiGuided1("");
    onResetSession();
  };

  useEffect(() => {
    setCurrentPersona(getStorage("currentLoggedInUser")?.Persona);
    // if (getStorage("currentLoggedInUser")?.Persona === "NIE") {
    //     setIsiGuided1("Write me a course outline for [number of lessons] on [course title]. ")
    // }
    // else {
    //     setIsiGuided1("Write me a [duration in mins] lesson plan for [student level, subject] students on [task and/or topic]")
    // }
  }, []);

  //adjust width
  const adjustWidthNumberOfLesson = () => {
    const minWidth = 55;
    const width = numberOfLesson.length * 8;

    if (!numberOfLesson) {
      return { width: `${minWidth}px` };
    } else {
      return { width: `${width}px` };
    }
  };

  const adjustWidthCourseTitle = () => {
    const minWidth = 80;
    const width = courseTitle.length * 8;

    if (!courseTitle) {
      return { width: `${minWidth}px` };
    } else {
      return { width: `${width}px` };
    }
  };
  const adjustWidthTopics = () => {
    const minWidth = 125;
    const width = topics.length * 7;

    if (!topics) {
      return { width: `${minWidth}px` };
    } else {
      return { width: `${width}px` };
    }
  };
  const adjustWidthDuration = (minW: number) => {
    const minWidth = minW ? minW : 100;
    const width = durationInMins.length * 8;

    if (!durationInMins) {
      return { width: `${minWidth}px` };
    } else {
      return { width: `${width}px` };
    }
  };
  const adjustWidthStudentLevel = (minW: number) => {
    const minWidth = minW ? minW : 140;
    const width = studentLevel.length * 7;

    if (!studentLevel) {
      return { width: `${minWidth}px` };
    } else {
      return { width: `${width}px` };
    }
  };

  return (
    <>
      {guidedCoursePage == 1 ? (
        <>
          <div className="d-flex flex-column" style={{ minHeight: "85vh" }}>
            <div className={"d-flex justify-content-end"}>
              <div
                className="d-flex align-items-center text-end gap-1 text-default-blue cursor-pointer"
                onClick={() => setResetModal(true)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <path
                    d="M2.76504 0.234856C2.83535 0.305179 2.87484 0.400544 2.87484 0.499981C2.87484 0.599417 2.83535 0.694783 2.76504 0.765106L1.53017 1.99998H6.06242C8.23704 1.99998 9.99992 3.76286 9.99992 5.93748C9.99992 8.11211 8.23704 9.87498 6.06242 9.87498C3.88779 9.87498 2.12492 8.11211 2.12492 5.93748C2.12492 5.83802 2.16443 5.74264 2.23475 5.67232C2.30508 5.60199 2.40046 5.56248 2.49992 5.56248C2.59938 5.56248 2.69476 5.60199 2.76508 5.67232C2.83541 5.74264 2.87492 5.83802 2.87492 5.93748C2.87492 6.56791 3.06186 7.18418 3.41211 7.70836C3.76236 8.23254 4.26018 8.64109 4.84262 8.88235C5.42506 9.1236 6.06596 9.18672 6.68427 9.06373C7.30258 8.94074 7.87054 8.63716 8.31632 8.19138C8.7621 7.7456 9.06568 7.17765 9.18867 6.55933C9.31166 5.94102 9.24854 5.30012 9.00729 4.71768C8.76603 4.13524 8.35748 3.63742 7.8333 3.28717C7.30912 2.93692 6.69285 2.74998 6.06242 2.74998H1.53017L2.76504 3.98486C2.80086 4.01945 2.82943 4.06083 2.84908 4.10658C2.86874 4.15233 2.87908 4.20154 2.87951 4.25133C2.87995 4.30112 2.87046 4.3505 2.8516 4.39659C2.83275 4.44267 2.8049 4.48454 2.76969 4.51975C2.73448 4.55496 2.69261 4.58281 2.64653 4.60166C2.60044 4.62052 2.55106 4.63001 2.50127 4.62957C2.45148 4.62914 2.40227 4.6188 2.35652 4.59914C2.31077 4.57949 2.26939 4.55092 2.23479 4.51511L0.359795 2.64011C0.289493 2.56978 0.25 2.47442 0.25 2.37498C0.25 2.27554 0.289493 2.18018 0.359795 2.10986L2.23479 0.234856C2.30512 0.164554 2.40048 0.125061 2.49992 0.125061C2.59936 0.125061 2.69472 0.164554 2.76504 0.234856Z"
                    fill="#002060"
                  />
                </svg>
                Reset Chat
              </div>
            </div>

            <div className="p-4 text-default-blue">
              {getStorage("currentLoggedInUser")?.Persona === "NIE" ? (
                <>
                  <h5 className="mb-5 text-center">Welcome to Course Design with ALEX!</h5>
                  <ol className="text-start p-0">
                    <li className="mb-3 font-abadimt-extralight">
                      In the text box below, you'll find a preset prompt. Please refrain from altering its structure, as
                      it helps me reference the right materials for creating your course outline. However, feel free to
                      include additional details about your course's objectives.
                    </li>
                    <li className="mb-3 font-abadimt-extralight">
                      After clicking ‘Submit’, I'll assist you in crafting your course outline, and you'll be able to
                      view it in the Word document. Let's collaboratively create your course outline!
                    </li>
                  </ol>
                </>
              ) : (
                <>
                  <h5 className="mb-5 text-center">Welcome to Create Lesson Plan with ALEX!</h5>
                  <ol className="text-start p-0">
                    <li className="mb-3 font-abadimt-extralight">
                      In the text box below, you'll find a preset prompt. Please refrain from altering its structure, as
                      it helps me reference the right materials for creating your Lesson Plan. However, feel free to
                      include additional details about your lesson’s objectives.
                    </li>
                    <li className="mb-3 font-abadimt-extralight">
                      After clicking ‘Submit’, I'll assist you in crafting your Lesson Plan, and you'll be able to view
                      it in the Word document. Let's collaboratively create your Lesson Plan!
                    </li>
                  </ol>
                </>
              )}
            </div>

            <form
              onSubmit={handleSubmit}
              className="frm-input-step-1 box-chat-callout bg-another-gray position-relative"
            >
              {currentPersona == "MOE" ? (
                <div
                  className={`d-flex gap-4 wrapper-input-chat justify-content-between align-items-end ${
                    durationInMins && studentLevel && topics ? "active" : ""
                  }`}
                >
                  <p className="text-dblue">
                    Write me a{" "}
                    <input
                      type="number"
                      autoFocus
                      placeholder="duration"
                      onChange={handleChangeDuration}
                      id={"a-input"}
                      className="templateInputBox durationInMins"
                      style={adjustWidthDuration(60)}
                    />{" "}
                    minutes lesson plan for{" "}
                    <input
                      type="text"
                      placeholder="student level, subjects"
                      onChange={handleChangeStudentLevel}
                      className="templateInputBox studentLevels"
                      style={adjustWidthStudentLevel(155)}
                    />{" "}
                    students on{" "}
                    <input
                      type="text"
                      placeholder="task and/or topic"
                      onChange={handleChangeTopics}
                      className="templateInputBox topicsChat"
                      style={adjustWidthTopics()}
                    />
                  </p>
                  <button type="submit" className="btn-transparent">
                    <svg
                      className="submit-normal"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.168 7.5L8.16797 5.5M8.16797 5.5L6.16797 7.5M8.16797 5.5L8.16797 10.5M14.168 8C14.168 11.3137 11.4817 14 8.16797 14C4.85426 14 2.16797 11.3137 2.16797 8C2.16797 4.68629 4.85426 2 8.16797 2C11.4817 2 14.168 4.68629 14.168 8Z"
                        stroke="#0F172A"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <svg
                      className="submit-active"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.67969 1.6001C5.08984 1.6001 2.17969 4.51025 2.17969 8.1001C2.17969 11.6899 5.08984 14.6001 8.67969 14.6001C12.2695 14.6001 15.1797 11.6899 15.1797 8.1001C15.1797 4.51025 12.2695 1.6001 8.67969 1.6001ZM9.03324 5.24654C8.93947 5.15278 8.8123 5.1001 8.67969 5.1001C8.54708 5.1001 8.4199 5.15278 8.32613 5.24654L6.32613 7.24654C6.13087 7.44181 6.13087 7.75839 6.32613 7.95365C6.5214 8.14891 6.83798 8.14891 7.03324 7.95365L8.17969 6.8072L8.17969 10.6001C8.17969 10.8762 8.40354 11.1001 8.67969 11.1001C8.95583 11.1001 9.17969 10.8762 9.17969 10.6001L9.17969 6.8072L10.3261 7.95365C10.5214 8.14891 10.838 8.14891 11.0332 7.95365C11.2285 7.75839 11.2285 7.44181 11.0332 7.24654L9.03324 5.24654Z"
                        fill="#00B0F0"
                      />
                    </svg>
                  </button>
                </div>
              ) : (
                <div
                  className={`d-flex wrapper-input-chat justify-content-between align-items-end ${
                    numberOfLesson && courseTitle ? "active" : ""
                  }`}
                >
                  <p style={{ color: "#001134" }}>
                    Write me a{" "}
                    <input
                      type="number"
                      placeholder="number"
                      autoFocus
                      onChange={handleChangeNumberOfLesson}
                      className="templateInputBox numberOfLesson"
                      style={adjustWidthNumberOfLesson()}
                    />{" "}
                    week(s) course outline on{" "}
                    <input
                      type="text"
                      placeholder="course title"
                      onChange={handleChangeCourseTitle}
                      className="templateInputBox courseTitle"
                      style={adjustWidthCourseTitle()}
                    />
                    .
                  </p>
                  <button type="submit" className="btn-transparent">
                    <svg
                      className="submit-normal"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.168 7.5L8.16797 5.5M8.16797 5.5L6.16797 7.5M8.16797 5.5L8.16797 10.5M14.168 8C14.168 11.3137 11.4817 14 8.16797 14C4.85426 14 2.16797 11.3137 2.16797 8C2.16797 4.68629 4.85426 2 8.16797 2C11.4817 2 14.168 4.68629 14.168 8Z"
                        stroke="#0F172A"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <svg
                      className="submit-active"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.67969 1.6001C5.08984 1.6001 2.17969 4.51025 2.17969 8.1001C2.17969 11.6899 5.08984 14.6001 8.67969 14.6001C12.2695 14.6001 15.1797 11.6899 15.1797 8.1001C15.1797 4.51025 12.2695 1.6001 8.67969 1.6001ZM9.03324 5.24654C8.93947 5.15278 8.8123 5.1001 8.67969 5.1001C8.54708 5.1001 8.4199 5.15278 8.32613 5.24654L6.32613 7.24654C6.13087 7.44181 6.13087 7.75839 6.32613 7.95365C6.5214 8.14891 6.83798 8.14891 7.03324 7.95365L8.17969 6.8072L8.17969 10.6001C8.17969 10.8762 8.40354 11.1001 8.67969 11.1001C8.95583 11.1001 9.17969 10.8762 9.17969 10.6001L9.17969 6.8072L10.3261 7.95365C10.5214 8.14891 10.838 8.14891 11.0332 7.95365C11.2285 7.75839 11.2285 7.44181 11.0332 7.24654L9.03324 5.24654Z"
                        fill="#00B0F0"
                      />
                    </svg>
                  </button>
                </div>
              )}

              {/*
                    <textarea rows={5} value={isiGuided1} onChange={handleChangeKonten}
                        placeholder={`Write me a course outline for [number of lessons] on [course title].`} />

                    */}

              {/* <div className="text-end mt-3">
                        <button
                            // onClick={() => {
                            //     onClickButtonStep2();
                            // }}
                            type={"submit"}
                            className="btn-dblue rounded-pill py-1 px-3"
                        >
                            Submit
                        </button>
                    </div> */}
            </form>
          </div>
          <Modal centered show={resetModal} onHide={() => setResetModal(false)} className="need-margin">
            <Modal.Body>
              <div className="text-center">
                <h5>
                  <b className="font-abadimt-probold text-default-blue">Reset the Chat?</b>
                </h5>
                <p className="font-abadimt-light text-default-blue font-md mb-3">
                  Hitting that reset chat button will wipe out our current conversations. Confirm the action?
                </p>

                <div className="d-flex mt-3 gap-3 justify-content-center">
                  <div className="col text-end">
                    <button
                      className={"btn-custom-2 rounded-pill font-abadimt-prolight"}
                      onClick={() => setResetModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div
                    className="col text-start"
                    onClick={() => {
                      handleClick("");
                      resetChat();
                    }}
                  >
                    <button className={"btn-custom-2 rounded-pill font-abadimt-prolight"}>Reset chat</button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <div className={"element"}>
          <Guided2
            goToGuidedPage={goToGuidedPage}
            handleClick={handleClick}
            onChangeisNavigationComingFromCourseDesign={onChangeisNavigationComingFromCourseDesign}
            isNavigationComingFromCourseDesign={isNavigationComingFromCourseDesign}
            onSetIsTE21={onSetIsTE21}
            onSetIsLearningResource={onSetIsLearningResource}
            onSetIs5C={onSetIs5C}
            isTE21={isTE21}
            is5C={is5C}
            isLearningResource={isLearningResource}
            onChangeTypeGeneration={onChangeTypeGeneration}
            typeGeneration={typeGeneration}
            questionToBeAsked={questionToBeAsked}
            onChangeAnswerResult={onChangeAnswerResult}
            // submitChat={handleSubmitChat}
            chatResponse={chatResponse}
            // isiKonten={isiKonten}
            setIsiKonten={handleSetIsiKonten}
            textingFunc={textingFunc}
            isiGuided1={isiGuided1}
            isiGuided1HTML={isiGuided1HTML}
            resetChat={resetChat}
            uuid={uuid}
            highlightedText={highlightedText}
            durationInMins={durationInMins}
            studentLevel={studentLevel}
            topics={topics}
          />
        </div>
      )}
    </>
  );
}
