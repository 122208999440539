/* eslint-disable prettier/prettier */
import React from "react";

const PromptResult = ({ routeHandler, pageHandler }) => {
    const routeChange = (value) => {
        routeHandler(value);
    }

    const pageChange = (value) => {
        pageHandler(value);
    }
    
    return (
        <div className="">
            <h1>Prompt Result</h1>
        </div>
    )
}

export default PromptResult;